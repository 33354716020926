

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import {  useState } from "react";

function Projects() {
    const [destruction, setDestruction] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    const [update, setUpdate] = useState<any>(false)



    return (

        <>
            <Header icon={'las la-stethoscope'} title="Equipo de Trabajo" description="Usuarios de la Entidad" />

            <div className="Widgets">

                {
                    destruction && <Form
                        method="delete"
                        path={`/directory/user?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                    />}

                {update && <Form
                    path={'/directory/user?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    header={{
                        icon: "las la-stethoscope",
                        title: 'Edición de Usuario'
                    }}
                    onSuccess={() => {
                        setUpdate(false)
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'status',
                                type: 'select',

                                title: "Estado de Acceso",
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'ACTIVE',
                                        label: 'Activado'
                                    },
                                    {
                                        id: 'INACTIVE',
                                        label: 'Desactivado'
                                    }
                                ]
                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },
                            {
                                name: 'surname',
                                title: "Apellido",
                                type: 'text',
                            },
                            {
                                name: 'position_id',
                                title: "Servicio",
                                type: 'select',
                                path: '/directory/position',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                title: "Responsabilidades y funciones",
                                type: 'tab',
                                inputs: [

                                    {
                                        name: 'capabilities',
                                        title: "Cargo Laboral",
                                        type: 'text',
                                    },
                                    {
                                        name: 'role',
                                        type: 'select',

                                        title: "Tipo de Usuario",
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'user',
                                                label: 'Usuario'
                                            },
                                            {
                                                id: 'admin',
                                                label: 'Administrador'
                                            }
                                        ]
                                    },
                                ]
                            },

                            {
                                title: "Información Profesional",
                                type: 'tab',
                                inputs: [

                                    {
                                        name: 'collegiate',
                                        title: "Número de Colegiado",
                                        description: 'Campo exclusivo para cargos clínicos',
                                        type: 'text',
                                    },
                                    {
                                        name: 'functional',
                                        title: "Número de Funcional",

                                        type: 'text',
                                    },
                                ]
                            },
                            {
                                title: "Datos de Acceso",
                                type: 'tab',
                                inputs: [
                                    {
                                        name: 'username',
                                        title: "Username",
                                        description: 'Identificador de acceso a la plataforma',
                                        type: 'text',
                                    },
                                    {
                                        name: 'signature',
                                        title: "Contraseña",
                                        description: 'Opcional, si desea cambiar la contraseña',
                                        type: 'password',
                                    },
                                ]
                            },
                            {
                                title: "Datos de Contacto",
                                type: 'tab',
                                inputs: [
                                    {
                                        name: 'phone',
                                        title: "Teléfono",
                                        type: 'text',
                                    },
                                    {
                                        name: 'email',
                                        title: "Correo Electrónico",
                                        description: "Email corporativo",
                                        type: 'text',
                                    },
                                ]
                            }
                            , {
                                name: 'selfie',
                                title: "Selfie",
                                description: "Foto visible del perfil al resto de usuarios",
                                type: 'file',
                                accept: "image/*;capture=camera"
                            },
                        ]
                    } />}
                <Widget >
                    <Table title="Listado de Usuarios" reload={tableKey} columns={
                        [
                            {
                                title: "",
                                width: 40,
                                paddingLeft: 0,
                                render: (item: any) => {
                                    // eslint-disable-next-line jsx-a11y/alt-text
                                    return <span className="avatar"><span className="avatar-image" style={{ backgroundImage: `url(${item.selfie ? item.selfie : '/graphics/images/avatar.jpg'})` }} /></span>
                                }
                            },
                            {
                                onPress: (data: any) => {
                                    setDestruction(data)
                                },
                                icon: 'las la-trash',
                                width: 50

                            }, {
                                onPress: (data: any) => {
                                    setUpdate(data)
                                },
                                icon: 'las la-pen',
                                width: 50

                            },

                            {
                                name: 'commision',
                                title: "Nombre y Apellido",
                                width: 250,
                                render: (item: any) => {
                                    return `${item.name} ${item.surname}`
                                }
                            },

                            {
                                name: 'phone',
                                title: "Teléfono",
                                width: 120
                            },
                            {
                                name: 'email',
                                title: "Email",
                                width: 200
                            }
                            , {
                                name: 'status',
                                title: "Estado",
                                width: 130,
                                render: (item: any) => {
                                    return <div className={`Status Status-${item.status}`}>{item.status}</div>
                                }
                            },
                            {
                                name: 'role',
                                title: "Rol",
                                width: 100
                            },
                            {
                                name: 'group.name',
                                title: "Grupo",
                                width: 250,
                                render: (item: any) => {
                                    return `${item.group?.name}`
                                }
                            },
                        ]
                    }
                        method="get"
                        path="/directory/user"
                    />
                </Widget>

            </div>
        </>

    );
}

export default Projects
