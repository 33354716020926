

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { setProject } from "../../../../redux/actions";
import { connect } from "react-redux";
// connect redux
const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setProject
}
interface DispatchProps {
    project: any,
    setProject: (s: any) => void


}
type Props = DispatchProps
function Projects(props: Props) {

    const [form, setForm] = useState(false)
    const [regionUrl, setRegionUrl] = useState('')
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    const navigate = useNavigate();
    const { search } = useLocation();

    useEffect(() => {
        // get region from query path
        //    get region param from query
        console.log('params:', search)
        // get param "region"
        const params = new URLSearchParams(search);
        const region = params.get('region');
        if (region) setRegionUrl(region)
        else setRegionUrl('')

    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (

        <>
            <Header icon="las la-archive" title="Expedientes" description="Trazabilidad del Proceso de Generación 3D" buttons={[{
                text: "Nuevo Expediente",
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />
            {
                regionUrl && <div className="Filtering">
                    Expedientes filtrados por <b>Área</b>
                </div>
            }
            <div className="Widgets">
                {
                    destruction && <Form
                        method="delete"
                        header={{
                            icon: 'las la-trash-alt',
                            title: 'Eliminar Expediente',
                            description: 'Esta acción no se puede deshacer'
                        }}
                        path={`/project?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                    />}
                {form && <Form
                    path={'/project'}
                    method={'post'}
                    header={{
                        icon: "las la-archive",
                        title: "Nuevo Expediente",
                    }}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setForm(false)
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    default={
                        {
                            share: 'yes'
                        }
                    }
                    inputs={
                        [{
                            name: 'share',
                            title: "Compartir Expediente",
                            description: "Hacer uso del repositorio de la entidad médica.",
                            label: "Acepto hacer uso del repositorio",
                            type: 'checkbox',
                            selected: 'yes',

                            unselected: 'no',

                        },

                        {
                            name: 'name',
                            title: "Nombre del Expediente",
                            description: 'Número de identificación interno del hospital',
                            type: 'text',

                        },

                        {
                            name: 'organization_id',
                            title: "Centro de Trabajo",
                            type: 'select',
                            path: '/organization',
                            columns: [
                                {
                                    name: 'name',
                                    title: 'Nombre',
                                }
                            ]
                        },
                        {
                            title: 'Responsables',

                            type: 'tab',
                            inputs: [{
                                name: 'invited_id',
                                title: "Invitado Clínico",
                                description: 'Usuario que se invita a participar en el Expediente',
                                type: 'select',
                                path: '/directory/user',
                                columns: [
                                    {

                                        render: (item: any) => {
                                            return `${item.name} ${item.surname}`
                                        },
                                    }
                                ]
                            }, {
                                name: 'technical_id',
                                title: "Responsable Técnico",
                                placeholder: "Automático del Centro de Trabajo",
                                description: 'Recibe notificación en la creación del expediente.',
                                type: 'select',
                                path: '/directory/user',
                                columns: [
                                    {

                                        render: (item: any) => {
                                            return `${item.name} ${item.surname}`
                                        },
                                    }
                                ]
                            }, {
                                name: 'designer_id',
                                title: "Ingeniería",
                                placeholder: "Automático del Centro de Trabajo",
                                description: 'Recibe notificación en las solicitudes de dispositivos.',
                                type: 'select',
                                path: '/directory/user',
                                columns: [
                                    {

                                        render: (item: any) => {
                                            return `${item.name} ${item.surname}`
                                        },
                                    }
                                ]
                            },
                            {
                                name: 'printer_id',
                                title: "Impresor",
                                placeholder: "Automático del Centro de Trabajo",
                                description: 'Recibe notificación en cada tarea de impresión.',
                                type: 'select',
                                path: '/directory/user',
                                columns: [
                                    {

                                        render: (item: any) => {
                                            return `${item.name} ${item.surname}`
                                        },
                                    }
                                ]
                            },
                            {
                                name: 'sterilizator_id',
                                title: "Esterilizador",
                                description: 'Recibe notificación en cada tarea de esterilziación.',

                                placeholder: "Automático del Centro de Trabajo",

                                type: 'select',
                                path: '/directory/user',
                                columns: [
                                    {

                                        render: (item: any) => {
                                            return `${item.name} ${item.surname}`
                                        },
                                    }
                                ]
                            },]
                        },


                        {
                            title: 'Datos del Paciente',
                            type: 'tab',
                            inputs: [
                                {
                                    name: 'born',
                                    title: "Fecha de Nacimiento",
                                    type: 'date',
                                },
                                {
                                    name: 'genre',
                                    title: "Género",
                                    type: 'select',
                                    columns: [
                                        {
                                            name: 'label',
                                            title: 'Nombre',
                                        }
                                    ],
                                    options: [
                                        {
                                            id: 'male',
                                            label: 'Masculino'
                                        },
                                        {
                                            id: 'female',
                                            label: 'Femenino'
                                        },
                                        {
                                            id: 'other',
                                            label: 'Otro'
                                        }
                                    ]
                                }]
                        },

                        {
                            title: 'Detalles del Caso',
                            type: 'tab',
                            inputs: [

                                {
                                    title: "Zona Anatómica",
                                    type: 'hierarchy',
                                    paths: [
                                        {
                                            name: 'region_id',
                                            title: 'Región',
                                            type: 'select',
                                            path: '/region',
                                            columns: [
                                                {
                                                    name: 'name',
                                                    title: 'Nombre',
                                                }
                                            ]
                                        },
                                        {
                                            name: 'tissue_id',
                                            title: 'Tejido',
                                            type: 'select',
                                            path: '/tissue',
                                            columns: [
                                                {
                                                    name: 'name',
                                                    title: 'Nombre',
                                                }
                                            ]
                                        }
                                    ]
                                },

                                {
                                    name: 'pathology_id',
                                    title: "Patología",
                                    type: 'select',
                                    path: '/pathology',
                                    columns: [
                                        {
                                            name: 'name',
                                            title: 'Nombre',
                                        }
                                    ]
                                },

                            ]
                        },
                        {
                            title: 'Datos de la Intervención',
                            type: 'tab',
                            inputs: [

                                {
                                    name: 'intervention',
                                    title: "Fecha Intervención",
                                    type: 'datetime',
                                },
                                {
                                    name: 'process',
                                    title: "Procedimiento",
                                    type: 'textarea',
                                }
                            ]
                        },

                        ]
                    } />}
                {update && <Form
                    path={'/project?id=' + update.id}
                    method={'put'}
                    header={{
                        icon: "las la-archive",
                        title: "Expediente",
                        description: "Este texto es una prueba"
                    }}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setUpdate(false)
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                disabled: true,
                                name: 'share',
                                title: "Compartir Expediente",
                                description: "Hacer uso del repositorio de la entidad médica.",
                                label: "Acepto hacer uso del repositorio",
                                type: 'checkbox',
                                selected: 'yes',
                                unselected: 'no',

                            },
                            {

                                name: 'status',
                                title: "Estado del Expediente",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'ACTIVE',
                                        label: 'Activo'
                                    },
                                    {
                                        id: 'FINALIZED',
                                        label: 'Finalizado'
                                    },
                                    {
                                        id: 'REJECTED',
                                        label: 'Rechazado'
                                    },
                                    {
                                        id: 'CANCELLED',
                                        label: 'Cancelado'
                                    }
                                ]
                            },

                            {
                                name: 'name',
                                disabled: true,
                                title: "Nombre del Expediente",
                                description: 'Número de identificación interno del hospital',
                                type: 'text',

                            },

                            {
                                disabled: true,
                                name: 'organization_id',
                                title: "Hospital",
                                type: 'select',
                                path: '/organization',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                title: 'Responsables del Expediente',

                                type: 'tab',
                                inputs: [{
                                    disabled: true,

                                    name: 'invited_id',
                                    title: "Médico Adjunto",
                                    type: 'select',
                                    path: '/directory/user',
                                    columns: [
                                        {

                                            render: (item: any) => {
                                                return `${item.name} ${item.surname}`
                                            },
                                        }
                                    ]
                                }, {
                                    disabled: true,

                                    name: 'designer_id',
                                    title: "Diseñador",
                                    type: 'select',
                                    path: '/directory/user',
                                    columns: [
                                        {

                                            render: (item: any) => {
                                                return `${item.name} ${item.surname}`
                                            },
                                        }
                                    ]
                                },
                                {
                                    disabled: true,

                                    name: 'printer_id',
                                    title: "Responsable de Impresión",
                                    type: 'select',
                                    path: '/directory/user',
                                    columns: [
                                        {

                                            render: (item: any) => {
                                                return `${item.name} ${item.surname}`
                                            },
                                        }
                                    ]
                                },
                                {
                                    disabled: true,

                                    name: 'sterilizator_id',
                                    title: "Esterilizador",
                                    type: 'select',
                                    path: '/directory/user',
                                    columns: [
                                        {

                                            render: (item: any) => {
                                                return `${item.name} ${item.surname}`
                                            },
                                        }
                                    ]
                                }, {
                                    disabled: true,

                                    name: 'technical_id',
                                    title: "Responsable Técnico",
                                    description: 'Valida el producto definitivo',
                                    type: 'select',
                                    path: '/directory/user',
                                    columns: [
                                        {

                                            render: (item: any) => {
                                                return `${item.name} ${item.surname}`
                                            },
                                        }
                                    ]
                                },]
                            },


                            {
                                title: 'Datos del Paciente',
                                type: 'tab',
                                inputs: [
                                    {
                                        name: 'born',
                                        title: "Fecha de Nacimiento",
                                        type: 'date',
                                        disabled: true,

                                    },
                                    {
                                        disabled: true,

                                        name: 'genre',
                                        title: "Género",
                                        type: 'select',
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'male',
                                                label: 'Masculino'
                                            },
                                            {
                                                id: 'female',
                                                label: 'Femenino'
                                            },
                                            {
                                                id: 'other',
                                                label: 'Otro'
                                            }
                                        ]
                                    }]
                            },
                            {
                                title: 'Detalles del Caso',
                                type: 'tab',
                                inputs: [
                                    {
                                        disabled: true,

                                        name: 'region_id',
                                        title: "Region",
                                        type: 'select',
                                        path: '/region',
                                        columns: [
                                            {
                                                name: 'name',
                                                title: 'Nombre',
                                            }
                                        ]
                                    },
                                    {
                                        disabled: true,

                                        name: 'pathology_id',
                                        title: "Patología",
                                        type: 'select',
                                        path: '/pathology',
                                        columns: [
                                            {
                                                name: 'name',
                                                title: 'Nombre',
                                            }
                                        ]
                                    },

                                ]
                            },
                            {
                                title: 'Datos de la Intervención',
                                type: 'tab',
                                inputs: [

                                    {

                                        name: 'intervention',
                                        title: "Fecha Intervención",
                                        type: 'datetime',
                                    },
                                    {
                                        name: 'process',
                                        title: "Procedimiento",
                                        type: 'textarea',
                                    }
                                ]
                            },
                        ]
                    } />}
                <Widget >
                    <Table reload={tableKey}
                        path={'/project?' + regionUrl}
                        title="Listado de Expedientes"
                        searchPlaceholder='Buscar por expediente...'

                        method={'get'}
                        columns={
                            [
                                {
                                    operator: 'project',
                                    onPress: (data: any) => {
                                        console.log(data)
                                        props.setProject(data)
                                    },
                                    iconDesactive: 'las la-stop',
                                    iconActive: 'las la-square-full',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        props.setProject(data)

                                        navigate(`/projects/view?id=${data.id}`)
                                    },
                                    icon: 'las la-eye',
                                    width: 50

                                },

                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },
                                {
                                    order: 'name',

                                    name: 'name',
                                    title: "Expediente",
                                    width: 150,
                                    render: (item: any) => {
                                        return <div className="Identifier">
                                            <i className="las la-archive"></i>

                                            <b>
                                                {
                                                    item.name.substring(0, 300)
                                                }</b>
                                        </div>
                                    },
                                },

                                {
                                    name: 'author.name',
                                    order: 'author_id',

                                    title: "Responsable",
                                    width: 250
                                    , render: (item: any) => {
                                        return `${item.author.name} ${item.author.surname}`
                                    }
                                },
                                {
                                    name: 'organization.name',
                                    title: "Hospital",
                                    width: 250,
                                    order: 'organization_id',


                                },

                                {
                                    name: 'status',
                                    title: "Status",
                                    order: 'status',

                                    width: 130,
                                    render: (item: any) => {
                                        return <div className={`Status Status-${item.status}`}>{item.status}</div>
                                    }

                                },
                                {
                                    name: 'creation',
                                    title: "Creación",
                                    type: 'date',
                                    width: 180,
                                    order: 'creation',
                                },


                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
