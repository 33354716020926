

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Pathologies() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            <Header icon="las la-biohazard" title="Patologías" description="Listado de patologías" buttons={[{
                text: "Nueva",
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">

                {
                    destruction && <Form
                        method="delete"
                        header={
                            {
                                icon: "las la-trash",
                                title: "Eliminar Patología",
                            }
                        }
                        path={`/pathology?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                    />}
                {form && <Form
                    path={'/pathology'}
                    method={'post'}
                    header={{
                        icon: "las la-biohazard",
                        title: "Nueva Patalogía",
                    }}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [

                            {
                                name: 'name',
                                title: "Nombre de la Patología",
                                type: 'text',
                            },
                            {
                                name: 'description',
                                title: "Descripción de la Patología",
                                type: 'textarea',
                            }
                        ]
                    } />}
                {update && <Form
                    path={'/pathology?id=' + update.id}
                    method={'put'}
                    header={{
                        icon: "las la-biohazard",
                        title: "Patalogía",
                    }}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },
                            {
                                name: 'description',
                                title: "Descripción",
                                type: 'textarea',
                            }
                        ]
                    } />}
                <Widget >
                    <Table reload={tableKey}
                        path={'/pathology'}
                        title="Listado de Patologías"
                        method={'get'}
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },

                                {
                                    order: 'name',
                                    name: 'name',
                                    title: "Nombre",
                                    width: 400

                                },

                                {
                                    order: 'creation',
                                    name: 'creation',
                                    type: 'date',
                                    title: "Creación",
                                    width: 150
                                }

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default Pathologies
