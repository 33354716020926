

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Regions() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            <Header icon="las la-shapes" title="Área" description="Listado de zonas anatómicas" buttons={[{
                text: "Nueva",
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">

                {
                    destruction && <Form
                        method="delete"
                        header={
                            {
                                icon: "las la-trash",
                                title: "Eliminar Área",
                            }
                        }
                        path={`/region?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }} />}
                {form && <Form
                    path={'/region'}
                    method={'post'}
                    header={{
                        icon: "las la-shapes",
                        title: "Área Anatómica"
                    }}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                        setForm(false)
                    }}
                    inputs={
                        [

                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },
                            {
                                name: 'description',
                                title: "Descripción",
                                type: 'textarea',
                            }, {
                                name: 'picture',
                                title: "Imagen de la Región",
                                description: "Imagen de fondo de los respositorios",
                                type: 'file',
                                accept: "image/*"
                            },
                        ]
                    } />}
                {update && <Form
                    path={'/region?id=' + update.id}
                    method={'put'}
                    header={{
                        icon: "las la-shapes",
                        title: "Área Anatómica"
                    }}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setForm(false)
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [

                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },

                            {
                                name: 'description',
                                title: "Descripción",
                                type: 'textarea',
                            }, {
                                name: 'picture',
                                title: "Imagen de la Región",
                                description: "Imagen de fondo de los respositorios",
                                type: 'file',
                                accept: "image/*"
                            },
                        ]
                    } />}
                <Widget>
                    <Table reload={tableKey}
                        title="Listado de Áreas Anatómicas"
                        path={'/region'}
                        method={'get'}
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },
                                {
                                    name: 'name',
                                    width: 50,
                                    render: (item: any) => {
                                        if (item.picture) {
                                            return <i className="las la-image"></i>
                                        }
                                        else {
                                            return <i style={{ color: '#c77878' }} className="las la-ban"></i>

                                        }
                                    }
                                },
                                {
                                    name: 'name',
                                    title: "Nombre",
                                    width: 400,
                                    order: 'name',

                                },

                                {
                                    order: 'creation',
                                    name: 'creation',
                                    type: 'date',
                                    title: "Creación",
                                    width: 150
                                }

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default Regions
