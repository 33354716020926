

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";

const mapStateToProps = (state: any, ownProps: any) => (state)


// function login props redux connect

interface DispatchProps {
    endpoint: string,
    environment: string,
    project: any,
    user: any,
    mode: string
}
type Props = DispatchProps

const Requests: React.FC<Props> = (props) => {
    let mode = 'normal'
    if (props.mode) mode = props.mode
    const [form, setForm] = useState(false)
    const [upload, setUpload] = useState<any>(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            {
                mode !== 'basic' &&

                <Header icon="las las la-vr-cardboard" title="Solicitudes de Dispositivo Médicos" buttons={[{
                    text: "Nuevo",
                    onClick: () => {
                        setForm(true)
                    },
                    icon: 'las la-plus'
                }]} />}

            <div className="Widgets">

                {
                    destruction && <Form
                        method="delete"
                        header={{
                            icon: "las la-vr-cardboard",
                            title: "Solicitud de Dispositivo Médico",
                        }}
                        path={`/request?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                    />}
                {form && <Form
                    path={'/request'}
                    header={{
                        icon: "las la-vr-cardboard",
                        title: "Solicitud de Dispositivo Médico",
                    }}
                    method={'post'}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setForm(false)
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [

                            {
                                name: 'project_id',
                                title: "Expediente",
                                type: 'select',
                                path: '/project',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'region_id',
                                title: "Región",
                                type: 'select',
                                path: '/region',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {

                                type: 'tab',
                                title: 'Detalles de la Solicitud',
                                inputs: [
                                    {
                                        name: 'sterilization',
                                        title: "Requiere Esterilización",
                                        description: "Se debe integrar en el sistema de trazabilidad el paso de la esterilización",
                                        label: "Este dispositivo debe esterilizarse",
                                        type: 'checkbox',
                                        selected: 'yes',
                                        unselected: 'no',

                                    },
                                    {
                                        name: 'name',
                                        title: "Nombre de la Solicitud",
                                        type: 'text',
                                    },
                                    {
                                        name: 'description',
                                        title: "Descripción",
                                        type: 'textarea',
                                    },
                                ]
                            },
                            {
                                type: 'tab',
                                title: 'Detalles del Dispositivo Médico',
                                inputs: [

                                    {
                                        name: 'device_name',
                                        type: 'select',

                                        title: "Tipo de Dispositivo Médico",
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [

                                            {
                                                id: 'digital',
                                                label: 'Digital Biomodelo 3D'
                                            },
                                            {
                                                id: 'digital',
                                                label: 'Digital VR o AR planeamiento'
                                            },
                                            {
                                                id: 'phisical',
                                                label: 'Físico Biomodelo educativo'
                                            },
                                            {
                                                id: 'phisical',
                                                label: 'Físico Biomodelo para planificación'
                                            },
                                            {
                                                id: 'phisical',
                                                label: 'Físico Biomodelo para preplaqueteo'
                                            },
                                            {
                                                id: 'phisical',
                                                label: 'Físico Biomodelo con simulación de cortes'
                                            },
                                            {
                                                id: 'phisical',
                                                label: 'Físico Diseño guía de corte'
                                            },
                                            {
                                                id: 'phisical',
                                                label: 'Físico Diseño Prótesis implantable'
                                            },
                                            {
                                                id: 'phisical',
                                                label: 'Físico Diseño de Simuladores'
                                            },
                                            {
                                                id: 'phisical',
                                                label: 'Físico Diseño ortesis'
                                            },
                                            {
                                                id: 'phisical',
                                                label: 'Físico Diseños Hospitalarios'
                                            }

                                        ]
                                    },
                                    {
                                        name: 'device_class',
                                        type: 'select',

                                        title: "Clase",
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'uno',
                                                label: 'Uno (I)'
                                            },
                                            {
                                                id: 'dosA',
                                                label: 'Dos A (IIA)'
                                            },
                                            {
                                                id: 'dosB',
                                                label: 'Dos B (IIB)'
                                            },
                                            {
                                                id: 'tresA',
                                                label: 'Tres A (IIIA)'
                                            },
                                            {
                                                id: 'tresB',
                                                label: 'Tres B (IIIB)'
                                            },
                                        ]
                                    },

                                    {
                                        name: 'specifications',
                                        title: "Parámetros",
                                        type: 'excel',
                                        columns: [
                                            {
                                                width: 150,
                                                name: 'param',
                                                title: 'Parámetro'
                                            },
                                            {
                                                name: 'data',
                                                title: 'Contenido'
                                            }
                                        ]
                                    },]
                            }



                        ]
                    } />}
                {
                    upload && <Form
                        path={'/study?id=' + upload.id}
                        method={'put'}
                        buttons={false}
                        header={{
                            icon: "las la-folder",
                            title: "Archivos de Diseño",
                        }}
                        default={upload}
                        backgroundClick={() => {
                            setUpload(false)
                        }}
                        onSuccess={() => {
                            setUpload(false)
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [

                                {
                                    name: 'design_file_url',
                                    file: 'design_file',
                                    title: "Archivo del Dispositivo Médico",
                                    description: "Descargue o actualice el archivo adjunto",
                                    type: 'google-storage',
                                    onSuccessUlr: '/request/upload-design?id=' + upload.id,
                                    onSuccessCallback: () => {
                                        setUpload(false)
                                        setTableKey(Math.random().toString(36).substring(7))
                                    }
                                },
                                {
                                    name: 'design_settings_print_url',
                                    file: 'design_settings_print',
                                    title: "Ficha de Impresión o Renderizado",
                                    description: "Descargue o actualice el archivo adjunto",
                                    type: 'google-storage',
                                    onSuccessUlr: '/request/upload-print?id=' + upload.id,
                                    onSuccessCallback: () => {
                                        setUpload(false)
                                        setTableKey(Math.random().toString(36).substring(7))
                                    }
                                }

                            ]
                        } />
                }
                {update && <Form
                    path={'/request?id=' + update.id}
                    method={'put'}
                    default={update}
                    header={{
                        icon: "las la-vr-cardboard",
                        title: "Solicitud de Dispositivo",
                    }}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setUpdate(false)
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [

                            {
                                disabled: true,
                                name: 'project_id',
                                title: "Expediente",
                                type: 'select',
                                path: '/project',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                disabled: true,
                                name: 'region_id',
                                title: "Región",
                                type: 'select',
                                path: '/region',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                type: 'tab',
                                title: 'Detalles de la Solicitud',
                                inputs: [
                                    {
                                        disabled: true,
                                        name: 'sterilization',
                                        title: "Requiere Esterilización",
                                        description: "Se debe integrar en el sistema de trazabilidad el paso de la esterilización",
                                        label: "Este dispositivo debe esterilizarse",
                                        type: 'checkbox',
                                        selected: 'yes',
                                        unselected: 'no',

                                    },
                                    {
                                        disabled: true,
                                        name: 'name',
                                        title: "Nombre",
                                        type: 'text',

                                    },
                                    {
                                        disabled: true,
                                        name: 'description',
                                        title: "Descripción",
                                        type: 'textarea',
                                    },
                                ]
                            },
                            {
                                type: 'tab',
                                title: 'Dispositivo',
                                inputs: [

                                    {
                                        disabled: true,
                                        name: 'device_name',
                                        title: "Dispositivo",
                                        type: 'text',
                                    },
                                    {
                                        disabled: true,
                                        name: 'device_class',
                                        type: 'select',

                                        title: "Clase",
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'uno',
                                                label: 'Uno (I)'
                                            },
                                            {
                                                id: 'dosA',
                                                label: 'Dos A (IIA)'
                                            },
                                            {
                                                id: 'dosB',
                                                label: 'Dos B (IIB)'
                                            },
                                            {
                                                id: 'tresA',
                                                label: 'Tres A (IIIA)'
                                            },
                                            {
                                                id: 'tresB',
                                                label: 'Tres B (IIIB)'
                                            },
                                        ]
                                    }, {
                                        disabled: true,
                                        name: 'specifications',
                                        title: "Guías de Corte",
                                        type: 'excel',
                                        columns: [
                                            {
                                                width: 150,
                                                name: 'param',
                                                title: 'Parámetro'
                                            },
                                            {
                                                name: 'data',
                                                title: 'Contenido'
                                            }
                                        ]
                                    },]
                            },
                            {

                                type: 'tab',
                                title: 'Detalles del Diseño Generado',
                                inputs: [

                                    {
                                        name: 'design_verification',
                                        type: 'select',

                                        title: "Verificación de los Requerimientos",
                                        description: 'El ingeniero ha de verificar el cumplimiento de los requerimientos de la solicitud del dispositivo médico para el diseño generado.',
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'yes',
                                                label: 'Positiva'
                                            },
                                            {
                                                id: 'no',
                                                label: 'Negativa'
                                            }
                                        ]
                                    },

                                    {
                                        name: 'design_status',
                                        type: 'select',

                                        title: "Estado del Diseño",
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'pending',
                                                label: 'No finalizado'
                                            },
                                            {
                                                id: 'finished',
                                                label: 'Diseño Finalizado'
                                            }
                                        ]
                                    },]
                            },
                            {
                                conditions: [
                                    {
                                        name: 'design_status',
                                        operator: '===',
                                        value: 'finished'
                                    }
                                ],
                                type: 'tab',
                                title: 'Detalles de la Revisión',
                                inputs: [


                                    {
                                        name: 'design_revision_meet',
                                        title: "Fecha de Reunión",
                                        type: 'datetime',
                                    },
                                    {
                                        name: 'design_revision_status',
                                        type: 'select',

                                        title: "Resultado de la Reunión de Aprobación",
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'pending',
                                                label: 'Pendiente'
                                            },
                                            {
                                                id: 'yes',
                                                label: 'Aprobado'
                                            },
                                            {
                                                id: 'no',
                                                label: 'No aprobado'
                                            }
                                        ]
                                    },
                                    {
                                        name: 'design_revision_observation',
                                        title: "Observaciones",
                                        type: 'textarea',
                                    },]
                            },
                            {
                                conditions: [
                                    {
                                        name: 'design_revision_status',
                                        operator: '===',
                                        value: 'yes'
                                    }
                                ],
                                type: 'tab',
                                title: 'Detalles de Impresión o Renderizado',
                                inputs: [


                                    {
                                        name: 'print_status',
                                        type: 'select',
                                        title: "Estado de la Impresión o Renderizado",
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'pending',
                                                label: 'Pendiente'
                                            },
                                            {
                                                id: 'finished',
                                                label: 'Impresión o Renderizado realizado correctamente'
                                            }
                                        ]
                                    },]
                            },

                            {
                                conditions: [
                                    {
                                        name: 'print_status',
                                        operator: '===',
                                        value: 'finished'
                                    },
                                    {
                                        name: 'sterilization',
                                        operator: '===',
                                        value: 'yes'
                                    }
                                ],
                                type: 'tab',
                                title: 'Detalles de la Esterilización',
                                inputs: [
                                    {
                                        name: 'esterilization_status',
                                        type: 'select',
                                        title: "Estado de la Esterilización",
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'finalized',
                                                label: 'Finalizado'
                                            },

                                            {
                                                id: 'no',
                                                label: 'Pendiente'
                                            }
                                        ]
                                    },
                                    {
                                        name: 'barcode',
                                        title: "Código de Esterilización",
                                        description: 'Clique en el campo y escanee el código de barras',
                                        type: 'text',
                                    },

                                ]
                            },

                            {
                                conditions: [
                                    {
                                        name: 'esterilization_status',
                                        operator: '===',
                                        value: 'finalized'
                                    }, {
                                        name: 'sterilization',
                                        operator: '===',
                                        value: 'yes'
                                    }
                                ],
                                type: 'tab',
                                title: 'Liberación del Dispositivo Médico ',
                                inputs: [

                                    {
                                        name: 'techical_revision_status',
                                        type: 'select',

                                        title: "Resultado del Dispositivo Médico",
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'pending',
                                                label: 'Pendiente'
                                            },
                                            {
                                                id: 'yes',
                                                label: 'Aprobado'
                                            },
                                            {
                                                id: 'no',
                                                label: 'No aprobado'
                                            }
                                        ]
                                    },
                                    {
                                        name: 'techical_revision_observation',
                                        title: "Observaciones",
                                        type: 'textarea',
                                    },]
                            },
                            {
                                conditions: [
                                    {
                                        name: 'print_status',
                                        operator: '===',
                                        value: 'finished'
                                    }, {
                                        name: 'sterilization',
                                        operator: '===',
                                        value: 'no'
                                    }
                                ],
                                type: 'tab',
                                title: 'Liberación del Dispositivo Médico ',
                                inputs: [

                                    {
                                        name: 'techical_revision_status',
                                        type: 'select',

                                        title: "Resultado del Dispositivo Médico",
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'pending',
                                                label: 'Pendiente'
                                            },
                                            {
                                                id: 'yes',
                                                label: 'Aprobado'
                                            },
                                            {
                                                id: 'no',
                                                label: 'No aprobado'
                                            }
                                        ]
                                    },
                                    {
                                        name: 'techical_revision_observation',
                                        title: "Observaciones",
                                        type: 'textarea',
                                    },]
                            }
                        ]
                    } />}
                <Widget>
                    <Table reload={tableKey}
                        path={'/request'}
                        title="Listado de Solicitudes de Dispositivo Médicos"
                        method={'get'}
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        if (data.techical_revision_status !== 'yes') return false;
                                        if (data.certificate_file) {
                                            window.open(data.certificate_file, '_blank');
                                            return false;
                                        }
                                        console.log(data)
                                        const path = '/request'
                                        let url = props.endpoint + path
                                        if (props.environment === 'dev') {
                                            url = 'http://127.0.0.1:5000' + path
                                        }

                                        // get param url:
                                        const url_params = new URLSearchParams(window.location.search)
                                        url = url + '?mode=pdf&id=' + data.id
                                        axios({
                                            method: 'get',
                                            url: url,
                                            data: {},
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'authorization': `Bearer ${props.user.access_token}`
                                            }
                                        }).then(response => {
                                            if (response.data.url) {
                                                // open in new tab:
                                                data.certificate_file = response.data.url
                                                window.open(response.data.url, '_blank');
                                            }
                                        });

                                    },
                                    render: (item: any) => {
                                        if (item.techical_revision_status === 'yes')
                                            return <i className="las la-file-signature"></i>
                                        return <i className="las la-ban" style={{ color: 'red', cursor: 'crosshair !important' }} ></i>

                                    },
                                    width: 50
                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpload(data)
                                    },
                                    icon: 'las la-folder',
                                    width: 50

                                },

                                mode !== 'basic' && {
                                    name: 'name',
                                    order: 'project_id',

                                    title: "Expediente",
                                    width: 150,
                                    render: (item: any) => {
                                        return <div className="Identifier">
                                            <i className="las la-archive"></i>
                                            <b>
                                                {
                                                    item.project.name.substring(0, 3)
                                                }
                                            </b>

                                            {
                                                item.project.name.substring(3, 300)
                                            }
                                        </div>
                                    },
                                },
                                {
                                    order: 'name',

                                    name: 'name',
                                    title: "Nombre",
                                    width: 200

                                },
                                {
                                    order: 'user_id',

                                    name: 'author.name',
                                    title: "Responsable",
                                    width: 250
                                    , render: (item: any) => {
                                        return `${item.author.name} ${item.author.surname}`
                                    }
                                },

                                {
                                    name: 'status',
                                    title: "Status",
                                    width: 130,
                                    render: (item: any) => {
                                        let status = 'None';
                                        let statusText = 'None'
                                        if (item.design_status === 'finished') {
                                            if (item.design_revision_status === 'yes') {
                                                if (item.print_status === 'finished') {
                                                    if (item.esterilization_status === 'finalized' || item.sterilization === 'no') {

                                                        if (item.techical_revision_status === 'yes') {
                                                            status = 'Finalized'
                                                            statusText = 'Finalizado'

                                                        }
                                                        else if (item.techical_revision_status === 'no') {
                                                            status = 'Denied'
                                                            statusText = 'Suspendido'

                                                        }
                                                        else {
                                                            status = 'Release'
                                                            statusText = 'Liberación'

                                                        }
                                                    }
                                                    else {
                                                        status = 'Sterilization'
                                                        statusText = 'Esterilización'

                                                    }
                                                }
                                                else {
                                                    status = 'Printing'
                                                    statusText = 'Impresión'

                                                }
                                            }
                                            else if (item.design_revision_status === '') {
                                                status = 'Examining'
                                                statusText = 'Revisión'

                                            }
                                            else {
                                                status = 'Failed'
                                                statusText = 'Rechazado'
                                            }
                                        }
                                        else {
                                            status = 'Pending'
                                            statusText = 'Diseño'
                                        }
                                        return <div className={`Status Status-${status.toUpperCase()}`}>{statusText}</div>
                                    }

                                },
                                // {

                                //     name: 'project.intervention',
                                //     type: 'date',
                                //     title: "Intervención",
                                //     width: 110
                                // }

                                {
                                    order: 'creation',

                                    name: 'creation',
                                    type: 'date',
                                    title: "Creación",
                                    width: 110
                                }

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default connect(mapStateToProps)(Requests)
