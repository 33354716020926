

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Studies({ mode = 'normal' }) {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [upload, setUpload] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            {
                mode !== 'basic' && <Header icon="las la-ruler" title="Estudios de Imagen" buttons={[{
                    text: "Nuevo",
                    onClick: () => {
                        setForm(true)
                    },
                    icon: 'las la-plus'
                }]} />}

            <div className="Widgets">

                {
                    destruction && <Form
                        method="delete"
                        header={{
                            icon: "las la-universal-access",
                            title: "Región Anatómica",
                            description: "Este texto es una prueba"
                        }}
                        path={`/study?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                    />}
                {form && <Form
                    path={'/study'}
                    method={'post'}
                    header={{
                        icon: "las la-ruler",
                        title: "Estudio de Imagen",
                    }}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setForm(false)
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'project_id',
                                help: 'Seleccione el expediente al que desea asociar el estudio de imagen, permitiendo al resto de usuarios asociados al expediente consultar su nuevo estudio de imagen.',
                                title: "Expediente",
                                type: 'select',
                                path: '/project',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {

                                name: 'region_id',
                                title: "Región Anatómica",
                                type: 'select',
                                path: '/region',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                title: "Detalles del Estudio de Imagen",
                                type: 'divisor',
                            },

                            {
                                name: 'name',
                                title: "Nombre del Estudio de Imagen",
                                type: 'text',
                            },


                            {
                                name: 'anonymous',
                                type: 'select',

                                title: "Verificación Anónimizada",
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'yes',
                                        label: 'No contiene datos del paciente'
                                    },
                                    {
                                        id: 'no',
                                        label: 'Contiene datos del paciente'
                                    }
                                ]
                            },
                            {
                                name: 'description',
                                title: "Descripción",
                                type: 'textarea',
                            },

                        ]
                    } />}
                {
                    upload && <Form
                        path={'/study?id=' + upload.id}
                        method={'put'}
                        buttons={false}
                        header={{
                            icon: "las la-ruler",
                            title: "Archivo del Estudio de Imagen",
                        }}
                        default={upload}
                        backgroundClick={() => {
                            setUpload(false)
                        }}
                        onSuccess={() => {
                            setUpload(false)
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [


                                {
                                    name: 'upload',
                                    file: 'file',
                                    title: "Archivo del Estudio de Imagen",
                                    description: "Descargue o actualice el archivo adjunto",
                                    type: 'google-storage',
                                    onSuccessUlr: '/study/upload?id=' + upload.id,
                                    onSuccessCallback: () => {
                                        setUpload(false)
                                        setTableKey(Math.random().toString(36).substring(7))
                                    }
                                }
                            ]
                        } />
                }
                {update && <Form
                    path={'/study?id=' + update.id}
                    method={'put'}
                    header={{
                        icon: "las la-ruler",
                        title: "Estudio de Imagen",
                    }}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                disabled: true,
                                name: 'project_id',
                                title: "Expediente",
                                type: 'select',
                                path: '/project',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                disabled: true,
                                name: 'region_id',
                                title: "Región Anatómica",
                                type: 'select',
                                path: '/region',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                disabled: true,
                                title: "Datos del Estudio de Imagen",
                                type: 'divisor',
                            },

                            {
                                disabled: true,
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },


                            {
                                disabled: true,
                                name: 'anonymous',
                                type: 'select',

                                title: "Verificación Anónimizada",
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'yes',
                                        label: 'No contiene datos del paciente'
                                    },
                                    {
                                        id: 'no',
                                        label: 'Contiene datos del paciente'
                                    }
                                ]
                            },
                            {
                                disabled: true,
                                name: 'description',
                                title: "Descripción",
                                type: 'textarea',
                            },
                        ]
                    } />}
                <Widget >
                    <Table reload={tableKey}
                        title="Listado de Estudios de Imagen"
                        path={'/study'}
                        method={'get'}
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash-alt',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpload(data)
                                    },
                                    icon: 'las la-folder',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },
                                mode !== 'basic' && {
                                    name: 'name',
                                    title: "Expediente",
                                    order: 'project_id',

                                    width: 150,
                                    render: (item: any) => {
                                        return <div className="Identifier">
                                            <i className="las la-archive"></i>
                                            <b>
                                                {
                                                    item.project.name.substring(0, 3)
                                                }
                                            </b>

                                            {
                                                item.project.name.substring(3, 300)
                                            }
                                        </div>
                                    },
                                },
                                {
                                    name: 'region.name',
                                    title: "Region",
                                    width: 200,
                                    order: 'region_id',
                                },
                                {
                                    name: 'name',
                                    title: "Nombre",
                                    width: 200,
                                    order: 'name',

                                },

                                {
                                    name: 'author.name',
                                    title: "Autor",
                                    order: 'author_id',

                                    width: 200,
                                    render: (item: any) => {
                                        return `${item.author.name} ${item.author.surname}`
                                    }

                                },

                                {
                                    order: 'creation',

                                    name: 'creation',
                                    title: "Hace...",
                                    type: 'since',
                                    width: 200
                                }

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default Studies
