import { useRef, useState } from "react";
interface DispatchProps {
    title: any
    url: string,



}
type Props = DispatchProps
const VideoGallery: React.FC<Props> = (props) => {
    const [play, setPlay] = useState(false)
    const [mute, setMute] = useState(true)
    // use ref video
    const videoRef = useRef<any>();
    return <div className="video">
        {
            !play && <div className="titles" dangerouslySetInnerHTML={props.title}>

            </div>
        }
        <div className="play" onClick={() => {

            if (play && videoRef) {

                videoRef?.current?.pause();
                setPlay(false)
            }
            else {
                if (videoRef !== null) {
                    // add to window
                    // @ts-ignore
                    if (!window.videos) window.videos = []
                    // @ts-ignore
                    if (!window.videosStop) window.videosStop = []

                    // @ts-ignore
                    window.videos.push(videoRef?.current)

                    // @ts-ignore
                    window.videosStop.push(setPlay)

                    // pause all:
                    // @ts-ignore
                    window.videos.forEach((v, index) => {
                        v.pause()
                        // @ts-ignore
                        window.videosStop[index](false)

                    })
                    // set frame 0
                    // ignore ts 
                    // @ts-ignore: Unreachable code error
                    if (document.getElementById('videoRef') && document.getElementById('videoRef').currentTime) {
                        let video: any = document.getElementById('videoRef')
                        video.currentTime = 0;
                    }
                    setMute(false)
                    videoRef?.current?.play();
                    setPlay(true)
                }
            }
            // set
        }}>
            <b></b>
            {
                play && <i className="las la-pause"></i>
            }
            {
                !play && <i className="las la-play"></i>
            }
        </div>
        {play &&
            <div className="mute" onClick={() => {
                setMute(!mute)
            }}>
                {
                    mute && <i className="la la-volume-mute"></i>
                }
                {
                    !mute && <i className="la la-volume-up"></i>
                }
            </div>}
        <video ref={videoRef} id="videoRef" loop width="100%" height="100%" muted={mute}>
            <source src={props.url} type="video/mp4" />
        </video>
    </div>
}

export default VideoGallery