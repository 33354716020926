import axios from 'axios'
import { connect } from 'react-redux'
import project from '../../scenes/b-onic/projects/scenes/project'
import Line from '../line'
import './index.css'


const mapStateToProps = (state: any, ownProps: any) => (state)


// function login props redux connect

interface DispatchProps {
    endpoint: string,
    environment: string,
    project: any,
    user: any
}
type Props = DispatchProps

const Passport: React.FC<Props> = (props) => {

    const renderDateTime = (dx: any) => {
        var d: any = new Date(dx); /* midnight in China on April 13th */
        return d.toLocaleString('en-US', { timeZone: 'Europe/Madrid' });
    }
    console.log(props.project)
    return (< div className='Passport '  >
        <div className='Passport-background' style={{ backgroundImage: 'url(' + props.project?.region?.picture + ')' }} />
        <div className='Passport-details'>
            <div className='Passport-details-title'>
                <em><em>{props.project.id}</em></em>
                <b>{props.project.pathology?.name}<b>Patología</b></b>
                <div></div>
                <b>{props.project.region?.name}<b>Región</b></b>
            </div>
            <div className='Passport-content'>
                <b>Detalles</b>

                {/*                
                <a target={'_blank'} href={props.project.certificate_file} rel="noreferrer">
                    DESCARGAR ARCHIVOOO
                </a> */}
                <Line icon={'las la-hospital-symbol'} title="Centro" detail={props.project?.organization?.name} />
                <Line icon={'las la-home'} title="Autor" detail={props.project.author?.name + ' ' + props.project?.author?.surname} />

                <Line icon={'las la-home'} title="Intervención" detail={renderDateTime(props.project?.intervention)} />
                {props.project?.genre && <Line icon={'las la-home'} title="Género" detail={props.project?.genre && (props.project?.genre === 'male' ? 'Masculino' : props.project?.genre === 'female' ? 'Femenino' : 'Otro')} />}
                {props.project?.tissue?.name && <Line icon={'las la-home'} title="Tejido" detail={props.project?.tissue?.name} />}
                <Line icon={'las la-home'} title="Nacimiento" detail={renderDateTime(props.project?.born)} />


                <b style={{ marginTop: '20px !important' }}>Responsables</b>

                {props.project.invited?.name && <Line icon={'las la-notes-medical'} title="Adjunto" detail={`${props.project.invited?.name} ${props.project.invited?.surname}`} />}

                {props.project.technical?.name && <Line icon={'las la-stethoscope'} title="Técnico" detail={`${props.project.technical.name} ${props.project.technical.surname}`} />}
                {props.project.printer?.name && <Line icon={'las la-stethoscope'} title="Impresión" detail={`${props.project.printer.name} ${props.project.printer.surname}`} />}
                {props.project.designer?.name && <Line icon={'las la-drafting-compass'} title="Diseñador" detail={`${props.project.designer.name} ${props.project.designer.surname}`} />}
                {props.project.sterilizator?.name && <Line icon={'las la-atom'} title="Esterilización" detail={`${props.project.sterilizator.name} ${props.project.sterilizator.surname}`} />}           
            </div>
        </div>
    </div>
    )
}

export default connect(mapStateToProps)(Passport)
