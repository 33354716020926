


import { connect } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { setProject } from "../../../../redux/actions";
import './gallery.css'
import {
    Link,
} from "react-router-dom";
import VideoGallery from "./galleryVideo";
const mapStateToProps = (state: any, ownProps: any) => (state)
const mapDispatchToProps = {
    setProject
}
// function login props redux connect

interface DispatchProps {
    user: any
    endpoint: string,
    title?: string,
    environment: string,
    project: any,
    setProject: (s: any) => void


}
type Props = DispatchProps

const Project: React.FC<Props> = (props) => {
    // loading project:
    const [regions, setRegions] = useState([])
    const [loading, setLoading] = useState(true)
    // use ref video
    const path = '/region?page=1&mode=gallery'
    let url = props.endpoint + path
    if (props.environment === 'dev') {
        url = 'http://127.0.0.1:5000' + path
    }
    useEffect(() => {


        axios({
            method: 'get',
            url: url,
            data: {},
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${props.user.access_token}`
            }
        }).then(response => {
            setLoading(false)
            if (response.data && response.data.items && response.data.items.length > 0) {
                setRegions(response.data.items)
            }
            else {
                alert('El caso no se encuentra disponible')
            }

        });

    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>

            {
                loading && <div className="Loading"></div>
            }
            {
                !loading && <>


                    <div className="Widgets ">
                        <div className="Gallery">
                            <div className="Gallery-header" >
                                <img src="/graphics/images/jorge-magana.svg" alt="" />
                                <p>Plataforma para <b>la gestión</b> y <b>la trazabilidad</b> de sus dispositivos médicos</p>
                                <em>Thomas Slingerland <i>Chief Executive Officer</i></em>
                                {/* <a href="/">
                                    ABRIR CASOS
                                    <i className="las la-angle-right"></i>
                                </a> */}

                                <div className="Gallery-header-logo" >
                                    <svg id="logo_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                                        <path id="Trazado_330" data-name="Trazado 330" d="M333.945,41.594a10.814,10.814,0,0,0-7.08,2.7V34.082h-5.588V65.019h5.545V62.9a10.737,10.737,0,0,0,7.124,2.74,12.024,12.024,0,0,0,0-24.044ZM333.475,60.6a6.965,6.965,0,1,1,6.7-6.982A6.844,6.844,0,0,1,333.475,60.6Z" transform="translate(-321.276 -33.33)" fill="#242424"></path>
                                    </svg>
                                </div>
                            </div>

                            {
                                regions.map((region: any, index) => {
                                    return <>

                                        {(index === 7) && <VideoGallery title={{ __html: "Sistema de Calidad<br/> <b>ISO 13485</b>" }} url="https://storage.googleapis.com/b-onic-public-materials/2.mp4" />}
                                        <Link className="Region" to={`/projects/region?region=${region.id}`} >
                                            <div>
                                                <div className="Region-background">
                                                    <div className="Gallery-header-logo">
                                                        <svg id="logo_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                                                            <path id="Trazado_330" data-name="Trazado 330" d="M333.945,41.594a10.814,10.814,0,0,0-7.08,2.7V34.082h-5.588V65.019h5.545V62.9a10.737,10.737,0,0,0,7.124,2.74,12.024,12.024,0,0,0,0-24.044ZM333.475,60.6a6.965,6.965,0,1,1,6.7-6.982A6.844,6.844,0,0,1,333.475,60.6Z" transform="translate(-321.276 -33.33)" fill="#242424"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="Region-background-image" style={{ backgroundImage: `url(${region.picture})` }}></div>
                                                    <b>
                                                        {
                                                            region.name
                                                        }
                                                    </b>
                                                </div>

                                                <span >
                                                    VER
                                                    <i className="las la-angle-right"></i>

                                                </span>
                                            </div>
                                        </Link>
                                        {(index === 0) && <VideoGallery title={{ __html: "¿Cuál es el valor diferencial de <b>b-onic</b>?" }} url="https://storage.googleapis.com/b-onic-public-materials/1.mp4" />}

                                        {(index === 16) && <VideoGallery title={{ __html: "<b>MDR</b><br/><em style='font-size:24px;text-style:normal;display:block;font-style:normal;margin-top:-8px'>Medical Device Regulation</em>" }} url="https://storage.googleapis.com/b-onic-public-materials/3.mp4" />}

                                    </>
                                })
                            }
                        </div>
                    </div>
                </>
            }</>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Project)
