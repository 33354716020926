

import { Widget } from "../../../../componets/Widget";
import Header from "../../../../componets/Header";
import Chat from "../../../../componets/Chat";
import NotificationsTable from './tables/notifications'
import Passport from "../../../../componets/Passport";
import Studies from "./studies";
import Requests from "./requests";
import Reviews from "./reviews";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { setProject } from "../../../../redux/actions";

const mapStateToProps = (state: any, ownProps: any) => (state)
const mapDispatchToProps = {
    setProject
}
// function login props redux connect

interface DispatchProps {
    user: any
    endpoint: string,
    title?: string,
    environment: string,
    project: any,
    setProject: (s: any) => void


}
type Props = DispatchProps

const Project: React.FC<Props> = (props) => {
    // loading project:
    const [loading, setLoading] = useState(true)
    useEffect(() => {

        const path = '/project'
        let url = props.endpoint + path
        if (props.environment === 'dev') {
            url = 'http://127.0.0.1:5000' + path
        }
        let setProject = props.setProject
        // get param url:
        const url_params = new URLSearchParams(window.location.search)
        const project_id = url_params.get('id')
        url = url + '?id=' + project_id
        axios({
            method: 'get',
            url: url,
            data: {},
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${props.user.access_token}`
            }
        }).then(response => {
            setLoading(false)
            if (response.data && response.data.items && response.data.items.length > 0) {
                setProject(response.data.items[0])
            }
            else {
                alert('El caso no se encuentra disponible')
            }

        });

    }, [])
    const project: any = {}
    return (
        <>

            {
                loading && <div className="Loading"></div>
            }
            {
                !loading && <>

                    <Header icon="las la-archive" title="Expediente" description={props.project?.name} />

                    <div className="Widgets ">
                        <div className="Widgets">
                            <Widget className="SlideUp">
                                <Passport project={project} />
                            </Widget>

                            <Widget title="Mensajes">
                                <Chat />
                            </Widget>


                            <Widget title="Notificaciones">
                                <NotificationsTable />
                            </Widget>


                        </div>
                        <Studies mode={'basic'} />
                        <Requests mode={'basic'} />
                        <Reviews moder={'basic'} />


                    </div>
                </>
            }</>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Project)
