

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useReducer, useState } from "react";

function Projects() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    const [update, setUpdate] = useState<any>(false)



    return (

        <>
            <Header icon="las la-hospital" title="Centros de Trabajo" buttons={[{
                text: "Nuevo Centro",
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">
                {
                    destruction && <Form
                        method="delete"
                        path={`/organization?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                    />}
                {form && <Form
                    header={{
                        icon: "las la-hospital",
                        title: "Crear Centro de Trabajo"
                    }}
                    method="post"
                    path="/organization"
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Nombre del Centro de Trabajo",
                                type: 'text',
                            },
                            {
                                name: 'collection_id',
                                title: "Tipo de Centro de Trabajo",
                                type: 'select',
                                path: '/collection',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'web',
                                title: "Web",
                                type: 'text',
                            },

                            {
                                name: 'document',
                                title: "Código de Identificación Fiscal",
                                type: 'text',
                            },
                            {
                                type: 'tab',
                                title: 'Responsables del Centro de Trabajo',
                                inputs: [
                                    {
                                        name: 'manager_id',
                                        title: "Responsable Técnico",
                                        help:'Perfil de usuario para el cumplimento de la normativa.',
                                        description:'Gestor del Fablab que recibe notificaciones con cada nuevo expediente.',
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        name: 'fablab_id',
                                        title: "Responsable de Vigilancia",
                                        description:"Perfil que no recibe notificaciones, pero que revisa el conjunto de los procesos.",
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        name: 'student_id',
                                        title: "Responsable de Estudios de Imagen",
                                        description: "Perfil que no recibe notificaciones, pero ofrece asistencia a los estudios de imagen.",
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        name: 'printer_id',
                                        title: "Responsable de Fabricación",
                                        help:"Perfil definido en la normativa como Responsable de Fabricación.",
                                        description: "Perfil asociado a los expedientes de forma predeterminada y que recibe notificaciones con cada nuevo expediente.",
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        name: 'quality_id',
                                        title: "Responsable de Cumplimiento de la Normativa",
                                        description:"Perfil que no recibe notificaciones, pero que asegura el cumplimiento de la normativa.",
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        name: 'quality_second_id',
                                        title: "Responsable Técnico Suplente",
                                        description: "Perfil que no recibe notificaciones, pero que asegura el cumplimiento de la normativa complementando la labor del responsable de Cumplimiento de la Normativa.",

                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },

                                    {
                                        name: 'designer_id',
                                        title: "Ingeniería",
                                        description: "Perfil seleccionado predeterminadamente para el diseño de los dispositivos.",
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },

                                    {
                                        name: 'librarian_id',
                                        title: "Gestión de Datos",
                                        description:'Perfil que no recibe notificaciones, pero que valida la correcta gestión de los datos.',
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        name: 'sterilization_id',
                                        title: "Responsable de Esterización",
                                        description: 'Perfil que recibe notificaciones de los expedientes que requieren esterilización.',
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },

                                ]
                            },

                            {
                                title: "Dirección Postal",
                                type: 'tab',
                                inputs: [
                                    {
                                        name: 'address_street',
                                        title: "Dirección",
                                        type: 'text',
                                    },
                                    {
                                        name: 'address_zip',
                                        title: "Código Postal",
                                        type: 'text',
                                    },
                                    {
                                        name: 'address_province',
                                        title: "Provincia",
                                        type: 'text',
                                    },
                                    {
                                        name: 'address_country',
                                        title: "País",
                                        type: 'text',
                                    },
                                ]
                            },

                        ]
                    } />}
                {update && <Form
                    path={'/organization?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Nombre del Centro de Trabajo",
                                type: 'text',
                            },
                            {
                                name: 'collection_id',
                                title: "Tipo de Centro de Trabajo",
                                type: 'select',
                                path: '/collection',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'web',
                                title: "Web",
                                type: 'text',
                            },

                            {
                                name: 'document',
                                title: "Código de Identificación Fiscal",
                                type: 'text',
                            },
                            {
                                type: 'tab',
                                title: 'Responsables del Centro de Trabajo',
                                inputs: [
                                    {
                                        name: 'manager_id',
                                        title: "Responsable Técnico",
                                        help: 'Perfil de usuario para el cumplimento de la normativa.',
                                        description: 'Gestor del Fablab que recibe notificaciones con cada nuevo expediente.',
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        name: 'fablab_id',
                                        title: "Responsable de Vigilancia",
                                        description: "Perfil que no recibe notificaciones, pero que revisa el conjunto de los procesos.",
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        name: 'student_id',
                                        title: "Responsable de Estudios de Imagen",
                                        description: "Perfil que no recibe notificaciones, pero ofrece asistencia a los estudios de imagen.",
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        name: 'printer_id',
                                        title: "Responsable de Fabricación",
                                        help: "Perfil definido en la normativa como Responsable de Fabricación.",
                                        description: "Perfil asociado a los expedientes de forma predeterminada y que recibe notificaciones con cada nuevo expediente.",
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        name: 'quality_id',
                                        title: "Responsable de Cumplimiento de la Normativa",
                                        description: "Perfil que no recibe notificaciones, pero que asegura el cumplimiento de la normativa.",
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        name: 'quality_second_id',
                                        title: "Responsable Técnico Suplente",
                                        description: "Perfil que no recibe notificaciones, pero que asegura el cumplimiento de la normativa complementando la labor del responsable de Cumplimiento de la Normativa.",

                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },

                                    {
                                        name: 'designer_id',
                                        title: "Ingeniería",
                                        description: "Perfil seleccionado predeterminadamente para el diseño de los dispositivos.",
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },

                                    {
                                        name: 'librarian_id',
                                        title: "Gestión de Datos",
                                        description: 'Perfil que no recibe notificaciones, pero que valida la correcta gestión de los datos.',
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },

                                    {
                                        name: 'sterilization_id',
                                        title: "Responsable de Esterización",
                                        description: 'Perfil que recibe notificaciones de los expedientes que requieren esterilización.',
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },


                                ]
                            },

                            {
                                title: "Dirección Postal",
                                type: 'tab',
                                inputs: [
                                    {
                                        name: 'address_street',
                                        title: "Dirección",
                                        type: 'text',
                                    },
                                    {
                                        name: 'address_zip',
                                        title: "Código Postal",
                                        type: 'text',
                                    },
                                    {
                                        name: 'address_province',
                                        title: "Provincia",
                                        type: 'text',
                                    },
                                    {
                                        name: 'address_country',
                                        title: "País",
                                        type: 'text',
                                    },
                                ]
                            },

                        ]
                    } />}
                <Widget>
                    <Table reload={tableKey}

                        title="Listado de Centros de Trabajo"
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                }, {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },
                                {
                                    name: 'name',
                                    title: "Nombre",
                                    width: 250,


                                },
                                {
                                    name: 'address_zip',
                                    title: "Código Postal",
                                    width: 150
                                },
                                {
                                    name: 'address_province',
                                    title: "Provincia",
                                    width: 150
                                },
                                {
                                    name: 'address_country',
                                    title: "País",
                                    width: 150
                                },
                                {
                                    name: 'creation',
                                    title: "Creación",
                                    type: 'date',
                                    width: 200
                                }
                            ]
                        }
                        method="get"
                        path="/organization"
                    />
                </Widget>

            </div>
        </>

    );
}

export default Projects
