

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";
import { connect } from "react-redux";
import { setProject } from "../../../../redux/actions";

const mapStateToProps = (state: any, ownProps: any) => (state)
const mapDispatchToProps = {
    setProject
}

// function login props redux connect

interface DispatchProps {
    user: any
    title?: string,
    environment: string,
    moder: string,
    project: any,
    setProject: (project: any) => void
}
type Props = DispatchProps

const Reviews: React.FC<Props> = (props) => {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <> {
            props.moder !== 'basic' &&
            <Header icon="las la-book-medical" title="Review" description="Listado de zonas anatómicas" buttons={[{
                text: "Nuevo",
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />}

            <div className="Widgets">

                {
                    destruction && <Form


                        method="delete"
                        header={{
                            icon: "las la-universal-access",
                            title: "Región Anatómica",
                            description: "Este texto es una prueba"
                        }}
                        path={`/review?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                    />}
                {form && <Form
                    path={'/review'}
                    method={'post'}
                    header={{
                        icon: "las la-signature",
                        title: "Review",
                    }}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setForm(false)
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [

                            {
                                name: 'project_id',
                                title: "Expediente",
                                type: 'select',
                                path: '/project',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                type: 'textarea',
                                name: 'description',
                                title: 'Observaciones del Procedimiento'
                            },
                            {
                                type: 'divisor',
                                title: 'Puntuaciones del Proceso'
                            },
                            {
                                name: 'esterilization',
                                type: 'select',

                                title: "Esterilización",
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 5,
                                        label: 'Sobresaliente'
                                    },
                                    {
                                        id: 4,
                                        label: 'Notable'
                                    },
                                    {
                                        id: 3,
                                        label: 'Suficiente'
                                    },
                                    {
                                        id: 2,
                                        label: 'Insuficiente'
                                    },
                                    {
                                        id: 1,
                                        label: 'Deficiente'
                                    }
                                ]
                            },
                            {
                                name: 'printer',
                                type: 'select',

                                title: "Impresión",
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 5,
                                        label: 'Sobresaliente'
                                    },
                                    {
                                        id: 4,
                                        label: 'Notable'
                                    },
                                    {
                                        id: 3,
                                        label: 'Suficiente'
                                    },
                                    {
                                        id: 2,
                                        label: 'Insuficiente'
                                    },
                                    {
                                        id: 1,
                                        label: 'Deficiente'
                                    }
                                ]
                            }, {
                                name: 'design',
                                type: 'select',

                                title: "Diseño",
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 5,
                                        label: 'Sobresaliente'
                                    },
                                    {
                                        id: 4,
                                        label: 'Notable'
                                    },
                                    {
                                        id: 3,
                                        label: 'Suficiente'
                                    },
                                    {
                                        id: 2,
                                        label: 'Insuficiente'
                                    },
                                    {
                                        id: 1,
                                        label: 'Deficiente'
                                    }
                                ]
                            },
                            {
                                name: 'software',
                                type: 'select',

                                title: "Software",
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 5,
                                        label: 'Sobresaliente'
                                    },
                                    {
                                        id: 4,
                                        label: 'Notable'
                                    },
                                    {
                                        id: 3,
                                        label: 'Suficiente'
                                    },
                                    {
                                        id: 2,
                                        label: 'Insuficiente'
                                    },
                                    {
                                        id: 1,
                                        label: 'Deficiente'
                                    }
                                ]
                            },
                        ]
                    } />}
                {update && <Form
                    path={'/review?id=' + update.id}
                    method={'put'}
                    header={{
                        icon: "las la-universal-access",
                        title: "Región Anatómica",
                        description: "Este texto es una prueba"
                    }}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setUpdate(false)
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'project_id',
                                title: "Expediente",
                                type: 'select',
                                path: '/project',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                type: 'textarea',
                                name: 'description',
                                title: 'Observaciones del Procedimiento'
                            },
                            {
                                type: 'divisor',
                                title: 'Puntuaciones del Proceso'
                            },
                            {
                                name: 'esterilization',
                                type: 'select',

                                title: "Esterilización",
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 5,
                                        label: 'Sobresaliente'
                                    },
                                    {
                                        id: 4,
                                        label: 'Notable'
                                    },
                                    {
                                        id: 3,
                                        label: 'Suficiente'
                                    },
                                    {
                                        id: 2,
                                        label: 'Aprobado'
                                    },
                                    {
                                        id: 1,
                                        label: 'Suspendido'
                                    }
                                ]
                            },
                            {
                                name: 'printer',
                                type: 'select',

                                title: "Impresión",
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 5,
                                        label: 'Sobresaliente'
                                    },
                                    {
                                        id: 4,
                                        label: 'Notable'
                                    },
                                    {
                                        id: 3,
                                        label: 'Suficiente'
                                    },
                                    {
                                        id: 2,
                                        label: 'Aprobado'
                                    },
                                    {
                                        id: 1,
                                        label: 'Suspendido'
                                    }
                                ]
                            }, {
                                name: 'design',
                                type: 'select',

                                title: "Diseño",
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 5,
                                        label: 'Sobresaliente'
                                    },
                                    {
                                        id: 4,
                                        label: 'Notable'
                                    },
                                    {
                                        id: 3,
                                        label: 'Suficiente'
                                    },
                                    {
                                        id: 2,
                                        label: 'Aprobado'
                                    },
                                    {
                                        id: 1,
                                        label: 'Suspendido'
                                    }
                                ]
                            },
                            {
                                name: 'software',
                                type: 'select',

                                title: "Software",
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 5,
                                        label: 'Sobresaliente'
                                    },
                                    {
                                        id: 4,
                                        label: 'Notable'
                                    },
                                    {
                                        id: 3,
                                        label: 'Suficiente'
                                    },
                                    {
                                        id: 2,
                                        label: 'Aprobado'
                                    },
                                    {
                                        id: 1,
                                        label: 'Suspendido'
                                    }
                                ]
                            },
                        ]
                    } />}
                <Widget>
                    <Table reload={tableKey}
                        title="Listado de Reviews"
                        path={'/review'}
                        method={'get'}
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },
                                props.moder !== 'basic' && {
                                    name: 'name',
                                    order: 'project_id',

                                    title: "Expediente",
                                    width: 150,
                                    render: (item: any) => {
                                        return <div className="Identifier">
                                            <i className="las la-archive"></i>
                                            <b>
                                                {
                                                    item?.project?.name.substring(0, 3)
                                                }
                                            </b>

                                            {
                                                item?.project?.name.substring(3, 300)
                                            }
                                        </div>
                                    },
                                },
                                {
                                    order: 'author_id',

                                    name: 'author',
                                    title: "Autor",
                                    width: 200,
                                    render: (item: any) => {
                                        return `${item?.author?.name} ${item?.author?.surname}`
                                    }
                                },
                                {
                                    order: 'esterilization',

                                    name: 'esterilization',
                                    title: "Esterilización",
                                    width: 130,
                                    type: 'rating',

                                },
                                {
                                    order: 'printer',

                                    name: 'printer',
                                    type: 'rating',
                                    title: "Impresión",
                                    width: 130

                                },
                                {
                                    order: 'design',

                                    type: 'rating',
                                    name: 'design',
                                    title: "Diseño",
                                    width: 130
                                },
                                {
                                    order: 'software',

                                    type: 'rating',
                                    name: 'software',
                                    title: "Software",
                                    width: 130
                                },

                                {
                                    order: 'creation',

                                    name: 'creation',
                                    title: "Creación",
                                    type: "date",
                                    width: 90
                                }

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Reviews)
