
import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ChatForm from './form';
import './index.css';

interface DispatchProps {
    user: any
    environment: string,
    project: any,
    endpoint: string,
}
type Props = DispatchProps
const mapStateToProps = (state: any, ownProps: any) => (state)

function Chat(props: Props) {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const loadMessages = () => {
        let pager = 0
        let path = '/message'
        let url = props.endpoint + path
        if (props.environment === 'dev') {
            url = 'http://127.0.0.1:5000' + path
        }
        url = url + '?mode=table&page=' + pager
        if (props.project) {
            url = url + '&project=' + props.project.id
        }

        axios({
            method: 'get',
            url: url,
            data: {},
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${props.user.access_token}`
            }
        }).then(response => {
            if (response.data) {
                let i = 0;
                let newMessage = false;
                let itemsSave = notifications
                for (i = 0; i < response.data.items.length; i++) {
                    // exist in notifications id in itemsSave
                    let exist = false;
                    let j = 0;
                    for (j = 0; j < itemsSave.length; j++) {
                        // @ts-ignore
                        if (itemsSave[j].id === response.data.items[i].id) {
                            exist = true;

                        }
                    }
                    if (exist === false) {
                        newMessage = true;
                        // @ts-ignore
                        itemsSave.push(response.data.items[i])
                    }
                }
                if (newMessage) {
                    console.log(itemsSave)
                    const itemsSaveClone: any = JSON.parse(JSON.stringify(itemsSave))
                    setNotifications(itemsSaveClone)
                }
                setScroll()
            }


            setLoading(false);
        }
        ).catch(error => {
            setError(error);
            setLoading(false);
        })
    }

    const setScroll = () => {

        const objDiv: any = document.getElementById("chatScroll");
        if (objDiv.mouse !== true) { objDiv.scrollTop = objDiv.scrollHeight; }
        else {
            let miniumDistance = objDiv.scrollTop + objDiv.offsetHeight - objDiv.scrollHeight

            if (miniumDistance > -200) {
                objDiv.scrollTop = objDiv.scrollHeight;
            }
        }
    }

    const setMouse = (status: any) => {

        const objDiv: any = document.getElementById("chatScroll");
        objDiv.mouse = status
    }


    useEffect(() => {
        loadMessages()
        const interval = setInterval(() => {
            loadMessages()
        }, 5000);
        return () => clearInterval(interval);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps    

    const sinceDate = (date: any) => {

        let date_ = new Date(date);
        let now = new Date();
        let diff = now.getTime() - date_.getTime();
        let seconds = Math.floor(diff / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        let time = '';
        if (days > 0) {
            time = days + ' días';
        }
        else if (hours > 0) {
            time = hours + ' horas';
        }
        else if (minutes > 0) {
            time = minutes + ' minutos';
        }
        else if (seconds > 0) {
            time = seconds + ' segundos';
        }
        else {
            time = 'unos segundos';
        }
        return time;
    }
    let authorLast: any = false
    return <div className='Chat'>
        {
            loading && <div style={{ position: 'relative' }}><div className='Loading'></div></div>
        }
        {
            error && <div>Error</div>
        }
        <ul id='chatScroll' onMouseEnter={() => {
            setMouse(true)
        }} onMouseLeave={() => {
            setMouse(false)
        }}>
            {
                notifications.map((message: any, index) => {
                    let authorNow = message.author.id
                    let showProfile = false
                    if (authorLast !== authorNow) {
                        showProfile = true;
                        authorLast = authorNow
                    }
                    if (props.user.id === message.author.id) {
                        showProfile = false;
                    }
                    return <li key={message.id} className={props.user.id === message.author.id ? 'me' : 'other'}>
                        {
                            showProfile && <>
                                <div style={{ backgroundImage: `url(${message.author.selfie ? message.author.selfie : '/graphics/images/avatar.jpg'})` }} className='avatar'></div>
                                <strong>{message.author.name} {message.author.surname}
                                    <b>{message?.author?.position?.name}
                                    </b>
                                </strong>
                                <span>{message.author.email}</span></>
                        }

                        <div className='message'>
                            <p>{message.body}

                            </p>
                            <em title={message.creation}>Hace {sinceDate(message.creation)}</em>
                        </div>
                    </li>
                })
            }
        </ul>
        <ChatForm onNewMessage={() => {

            setMouse(false)
            loadMessages()
        }} />
    </div>
}


export default connect(mapStateToProps)(Chat)
