

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Collections() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            <Header title="Tipos de Centros de Trabajo" icon="las la-tag" description="Conjunto de Centros de Trabajo" buttons={[{
                text: "Nuevo Tipo",

                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">
                {
                    destruction && <Form
                        method="delete"
                        path={`/collection?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                    />}
                {form && <Form
                    path={'/collection'}
                    method={'post'}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    header={{
                        title: 'Nuevo Tipo',
                        icon: 'las la-tag'
                    }}
                    onSuccess={() => {
                        setForm(false)

                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [

                            {
                                name: 'name',
                                title: "Nombre del Tipo",
                                type: 'text',
                            },

                            {
                                name: 'description',
                                title: "Descripción",
                                type: 'textarea',
                            }
                        ]
                    } />}
                {update && <Form
                    path={'/collection?id=' + update.id}
                    method={'put'}
                    header={{
                        title: 'Editar Colección',
                        icon: "las la-tag"
                    }}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [

                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                                placeholder: 'Productl proyecto',
                            }, {
                                name: 'description',
                                title: "Descripción",
                                type: 'text',
                                placeholder: 'Productl proyecto',
                            }

                        ]
                    } />}
                <Widget >
                    <Table reload={tableKey}
                        path={'/collection'}
                        method={'get'} search={false}
                        title="Listado de Tipos de Centros de Trabajo"
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },

                                {
                                    name: 'name',
                                    title: "Nombre",
                                    width: 400

                                },

                                {
                                    name: 'creation',
                                    title: "Creación",
                                    type: 'date',
                                    width: 200
                                }

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default Collections
