


import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Form from '../Form';
import './index.css';

interface DispatchProps {
    user: any,
    endpoint: string,
    environment: string,
    project: any,
    onNewMessage: () => void

}
type Props = DispatchProps
const mapStateToProps = (state: any, ownProps: any) => (state)

function ChatForm(props: Props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState(false);

    return <div className='Chat-form'>
        {
            loading && <div>Loading...</div>
        }
        {
            error && <div>Error</div>
        }
        <form autoComplete={'off'} onSubmit={async (e) => {
            e.stopPropagation();
            setLoading(true)
            e.preventDefault();
            setError(false)
            setErrors(false)
            let path = '/message'
            let url = props.endpoint + path
            if (props.environment === 'dev') {
                url = 'http://127.0.0.1:5000' + path
            }
            // get all inputs json string
            // get form dom tag this
            let that = e.target

            const formData = new FormData();
            // @ts-ignore
            let value = document.getElementsByName('body')[0].value;
            // @ts-ignore

            document.getElementsByName('body')[0].value = '';

            formData.append('body', value)
            formData.append('project_id', props.project.id)





            // send data with Axios to url

            setTimeout(() => {
                const headers = {

                    "Content-Type": "multipart/form-data",
                    // beraarer token props.user
                    'Authorization': 'Bearer ' + props.user.access_token
                }
                axios({
                    method: 'post',
                    url: url,
                    data: formData,

                    headers: headers
                })
                    .then(() => {
                        setLoading(false)
                        // @ts-ignore

                        props.onNewMessage()
                        // // if response.data is string
                        // if (typeof response.data === 'string') {
                        //     console.log('error')
                        //     return false;
                        // }

                        // console.log(response)
                        // setLoading(false)
                        // console.log(response);
                        // if (props.method === 'delete') {
                        //     props.audio && soundDelete()
                        // }
                        // else props.audio && soundEnter()
                        // setSuccess(true)
                        // props.onSuccess && props.onSuccess(response)

                    }
                    ).catch(({ response }) => {

                        setErrors(response.data.errors)
                        setLoading(false)

                    })
            }, 200);

        }}
        >
            {
                loading && <div className="Loading" ></div>
            }
            <input name="body" placeholder='Escribir aquí...' />
            <button type={'submit'} >Send</button>
        </form>
    </div>
}


export default connect(mapStateToProps)(ChatForm)
