
import { connect } from "react-redux";
import { setLanguage, setProfile, setProfileGithub, setScheme, setSchemeGithub, setLanguages, setLanguagesGithub } from '../redux/actions'

// Schemes:
import Menu from "../componets/Menu";


import {
    Routes,
    Route,
    useResolvedPath,
    useMatch
} from "react-router-dom";
import Users from "./b-onic/users/scenes/home";
import Organizations from "./b-onic/organizations/scenes/home";
import Joyride from 'react-joyride';


import Projects from "./b-onic/projects/scenes/home";
import Contacts from "./b-onic/organizations/scenes/contacts";
import Collections from "./b-onic/organizations/scenes/collections";

import Meets from "./b-onic/projects/scenes/meets";
import Regions from "./b-onic/projects/scenes/regions";
import Tissues from "./b-onic/projects/scenes/tissues";
import Requests from "./b-onic/projects/scenes/requests";
import Pathologies from "./b-onic/projects/scenes/pathologies";
import Studies from "./b-onic/projects/scenes/studies";
import Sterilizations from "./b-onic/projects/scenes/sterilizations";
import Reviews from "./b-onic/projects/scenes/reviews";
import Project from "./b-onic/projects/scenes/project";
import Positions from "./b-onic/users/scenes/positions";
import Groups from "./b-onic/groups/scenes/home";
import Gallery from "./b-onic/projects/scenes/gallery";
// connect redux
const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguage, setProfile, setProfileGithub, setScheme, setSchemeGithub, setLanguages, setLanguagesGithub
}
interface DispatchProps {
    user: any,
    organization: any,
    repository: any,
    project: any,
    scheme: any,
    language: any,
    theme: any,
    setProfile: (s: any) => void
    setLanguage: (s: any) => void
    setProfileGithub: (s: any) => void
    setScheme: (s: any) => void
    setSchemeGithub: (s: any) => void
    setLanguages: (s: any) => void
    setLanguagesGithub: (s: any) => void


}
type Props = DispatchProps
function Scenes(props: Props) {
    //  pathname is calendar
    let resolved = useResolvedPath('/calendar');
    let match = useMatch({ path: resolved.pathname, end: false });
    let className: string = 'Content'

    if (match) className = "Content-big"
    const
        steps: any = [
            {
                target: '.logo',
                content: 'This is my awesome feature!',
            },
            {
                target: '.my-other-step',
                content: 'This another awesome feature!',
            },

        ];

    return (
        <>


            <div className="App">






                <Menu brand='bonic' logotype={{ subtitle: 'Fablab Manager' }} />


                {props.user && <div className={className}>
                    <Routes>
                        <Route path="/organizations" element={<Organizations />} />
                        <Route path="/organizations/" element={<Organizations />} />
                        <Route path="/organizations" element={<Organizations />} />
                        <Route path="/organizations" element={<Organizations />} />
                        <Route path="/organizations" element={<Organizations />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/users/positions" element={<Positions />} />

                        <Route path="/organizations" element={<Organizations />} />
                        <Route path="/organizations/contacts" element={<Contacts />} />
                        <Route path="/organizations/collections" element={<Collections />} />

                        <Route path="/projects/view" element={<Project />} />
                        <Route path="/projects" element={<Projects key={'projects-list'} />} />
                        <Route path="/projects/region" element={<Projects />} />
                        <Route path="/projects/regions" element={<Regions />} />
                        <Route path="/projects/tissues" element={<Tissues />} />
                        <Route path="/projects/requests" element={<Requests />} />
                        <Route path="/projects/sterilizations" element={<Sterilizations />} />
                        <Route path="/projects/reviews" element={<Reviews />} />
                        <Route path="/projects/pathologies" element={<Pathologies />} />
                        <Route path="/projects/studies" element={<Studies />} />
                        <Route path="/projects/studies" element={<Studies />} />
                        <Route path="/projects/pending" element={<Projects />} />
                        <Route path="/projects/development" element={<Projects />} />
                        <Route path="/projects/finished" element={<Projects />} />
                        <Route path="/projects/tracking" element={<Projects />} />
                        <Route path="/projects/rejected" element={<Projects />} />
                        <Route path="/gallery" element={<Gallery />} />

                        <Route path="/projects/meets" element={<Meets />} />
                        <Route path="/groups" element={<Groups />} />


                    </Routes>

                </div>}
                {
                    !props.user && <div className="Video">
                        <video autoPlay loop muted onPlay={() => {
                            const x: any = document.querySelector('video')
                            if (x && x) {
                                const v: any = x
                                v.style.opacity = '1'
                            }
                        }}>
                            <source src="/graphics/videos/alicunde.mp4" type="video/mp4" />
                        </video>


                    </div>
                }

            </div>

        </>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Scenes)
