import React from 'react';
// import styles
import './index.scss';
interface Props {
    title: string,
    detail: string,
    style?: any
    icon?: any
}

const Line: React.FC<Props> = (props) => {
    return (
        <div className="Line" style={props.style} >
            {
                props.icon && <i className={props.icon}></i>
            }
            <b>{props.title}</b>

            <span>{props.detail}</span>

        </div>
    );
};

export default Line;