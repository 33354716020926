

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Contacts() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            <Header title="Contactos" icon="las la-user-tag" description="Equipo asociado a Centros de Trabajo" buttons={[{
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus',
                text: 'Nuevo Contacto'
            }]} />

            <div className="Widgets">
                {
                    destruction && <Form
                        method="delete"
                        path={`/contact?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                    />}
                {form && <Form
                    path={'/contact'}
                    method={'post'}
                    header={{
                        title: 'Nuevo Contacto',
                        icon: 'las la-pen'
                    }}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setForm(false)
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'organization_id',
                                title: "Centro de Trabajo",
                                type: 'select',
                                path: '/organization',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },
                            {
                                name: 'surname',
                                title: "Apellido",
                                type: 'text',
                            },
                            {
                                type: 'tab',
                                title: 'Datos de Contacto',
                                inputs: [
                                    {
                                        name: 'email',
                                        title: "Email",
                                        type: 'text',
                                    },
                                    {
                                        name: 'phone',
                                        title: "Teléfono",
                                        type: 'text',
                                    }
                                ]
                            },
                            {
                                type: 'tab',
                                title: 'Información Complementaria',
                                inputs: [
                                    {
                                        name: 'job',
                                        title: "Cargo Laboral",
                                        type: 'text',
                                    },
                                    {
                                        name: 'personal',
                                        title: "Información Personal",
                                        type: 'textarea',
                                    }
                                ]
                            }


                        ]
                    } />}
                {update && <Form
                    path={'/contact?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'organization_id',
                                title: "Centro de Trabajo",
                                type: 'select',
                                path: '/organization',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },
                            {
                                name: 'surname',
                                title: "Apellido",
                                type: 'text',
                            },
                            {
                                type: 'tab',
                                title: 'Datos de Contacto',
                                inputs: [
                                    {
                                        name: 'email',
                                        title: "Email",
                                        type: 'text',
                                    },
                                    {
                                        name: 'phone',
                                        title: "Teléfono",
                                        type: 'text',
                                    }
                                ]
                            },
                            {
                                type: 'tab',
                                title: 'Información Complementaria',
                                inputs: [
                                    {
                                        name: 'job',
                                        title: "Cargo Laboral",
                                        type: 'text',
                                    },
                                    {
                                        name: 'personal',
                                        title: "Información Personal",
                                        type: 'textarea',
                                    }
                                ]
                            }
                        ]
                    } />}
                <Widget >
                    <Table reload={tableKey}
                        path={'/contact'}
                        method={'get'}
                        title="Listado de Contactos"
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },
                                {
                                    name: 'organization.name',
                                    title: "Centro de Trabajo",
                                    width: 200

                                },
                                {
                                    title: "Nombre y Apellido",
                                    width: 350,
                                    render: (item: any) => {
                                        return `${item.name} ${item.surname}`
                                    }
                                },

                                {
                                    name: 'email',
                                    title: "Email",
                                    width: 250

                                },
                                {
                                    name: 'phone',
                                    title: "Teléfono",
                                    width: 200

                                }


                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default Contacts
