
import axios from 'axios';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './index.css';

interface DispatchProps {
    user: any
    environment: string,
    project: any,
    endpoint: string,
}
type Props = DispatchProps
const mapStateToProps = (state: any, ownProps: any) => (state)

function Notifications(props: Props) {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const sinceDate = (date: any) => {

        let date_ = new Date(date);
        let now = new Date();
        let diff = now.getTime() - date_.getTime();
        let seconds = Math.floor(diff / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        let time = '';
        if (days > 0) {
            time = days + ' días';
        }
        else if (hours > 0) {
            time = hours + ' horas';
        }
        else if (minutes > 0) {
            time = minutes + ' minutos';
        }
        else if (seconds > 0) {
            time = seconds + ' segundos';
        }
        else {
            time = 'unos segundos';
        }
        return time;
    }
    useEffect(() => {
        let pager = 0
        let path = '/notification'
        let url = props.endpoint + path
        if (props.environment === 'dev') {
            url = 'http://127.0.0.1:5000' + path
        }
        url = url + '?mode=table&page=' + pager
        if (props.project) {
            url = url + '&project=' + props.project.id
        }

        axios({
            method: 'get',
            url: url,
            data: {},
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${props.user.access_token}`
            }
        }).then(response => {
            if (response.data) {
                setNotifications(response.data.items)

            }


            setLoading(false);
        }
        ).catch(error => {
            setError(error);
            setLoading(false);
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <div className='Notifications'>
        {
            loading && <div>Loading...</div>
        }
        {
            error && <div>Error</div>
        }
        <ul>
            {
                notifications.map((message: any, index) => {
                    return <li key={index}>
                        {message.type === 'study_creation' && <i className={'las la-ruler'}></i>}
                        {message.type === 'creation_project' && <i className={'las la-archive'}></i>}
                        {message.type === 'request_creation' && <i className={'las la-vr-cardboard'}></i>}
                        {message.type === 'review_creation' && <i className={'las la-signature'}></i>}

                        <p>
                            {
                                message.type === 'study_creation' && 'Estudio de Imagen'
                            }
                            {
                                message.type === 'creation_project' && 'Proyecto'
                            }
                            {
                                message.type === 'request_creation' && 'Solicitud de Dispositivo Médicos'
                            }
                            {
                                message.type === 'review_creation' && 'Review'
                            }

                        </p>

                        <em>Hace {sinceDate(message.creation)}  {
                            ' por '
                        }
                            <b>
                                {
                                    message.author.name + ' ' + message.author.surname
                                }
                            </b></em>


                    </li>
                })
            }
        </ul>
    </div>
}


export default connect(mapStateToProps)(Notifications)
