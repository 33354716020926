import React, { useEffect, useState } from 'react';
// import styles
import { setUser, clear, setAudio, setProject, setRole, setEnvironment, setRepository } from '../../redux/actions'
import './styles.css'
import {
    Link,
    useMatch,
    useNavigate,
    useResolvedPath,
} from "react-router-dom";


import type { LinkProps } from "react-router-dom";

import useSound from 'use-sound';
import Form from '../Form';
import { connect } from "react-redux";
import MenuButtonsBonic from './bonic.json'
import MenuButtonsBio from './bio.json'
import MenuButtonsAlicunde from './alicunde.json'
import VersionComponent from '../version';
const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setUser, clear, setProject, setRepository, setAudio, setEnvironment, setRole
}
// function login props redux connect

interface DispatchProps {
    history: any,
    brand: string,
    repository: any,
    user: any,
    project: any,
    setProject: (project?: any) => void,
    setUser: (s: any) => void
    environment: string,
    role: string,
    setEnvironment: (s: string) => void
    setRole: (s: string) => void
    clear: () => void
    audio: any
    setAudio: (s: any) => void
    setRepository: () => void,
    logotype: {
        title: string,
        subtitle: string
    }
}
type Props = DispatchProps

const Menu: React.FC<Props> = (props) => {
    const [directory, setDirectory] = useState<string | null>('login');
    const [registerSuccess, setRegisterSuccess] = useState<boolean>(false);
    const [soundClick] = useSound(
        '/sounds/pop-down.mp3',
        { volume: 0.5 }
    );
    const [soundEnter] = useSound(
        '/sounds/pop-up-on.mp3',
        { volume: 0.2 }
    );

    const navigate = useNavigate();
    const [audioOff] = useSound(
        '/sounds/switch-off.mp3',
        { volume: 1 }
    );
    const [audioOn] = useSound(
        '/sounds/switch-on.mp3',
        { volume: 1 }
    );
    const [transitionLeft] = useSound(
        '/sounds/Expand.m4a',
        { volume: 0 }
    );
    let menu: any = []
    if (props.brand === "bonic") menu = MenuButtonsBonic.menu
    if (props.brand === "alicunde") menu = MenuButtonsAlicunde.menu
    if (props.brand === "bio") menu = MenuButtonsBio.menu
    useEffect(() => {
        console.log('User:', props.user)
        if (props.user) {
            setDirectory(null)
        }
        else {
            setDirectory('login')
        }
    }, [props.user])
    return (
        <>

            <div className="Menu" >
                <div className='logo'>
                    <b>

                        {props.logotype.title ? props.project && props.project.brand ? props.project.brand.title : props.logotype.title :
                            <svg id="logo_1" xmlns="http://www.w3.org/2000/svg" width="131.142" height="32.55" viewBox="0 0 131.142 32.55">
                                <g id="Grupo_245" data-name="Grupo 245">
                                    <path id="Trazado_330" data-name="Trazado 330" d="M333.945,41.594a10.814,10.814,0,0,0-7.08,2.7V34.082h-5.588V65.019h5.545V62.9a10.737,10.737,0,0,0,7.124,2.74,12.024,12.024,0,0,0,0-24.044ZM333.475,60.6a6.965,6.965,0,1,1,6.7-6.982A6.844,6.844,0,0,1,333.475,60.6Z" transform="translate(-321.276 -33.33)" fill="#242424" />
                                    <rect id="Rectángulo_136" data-name="Rectángulo 136" width="15.442" height="4.863" transform="translate(27.297 16.043)" fill="#242424" />
                                    <path id="Trazado_331" data-name="Trazado 331" d="M392.3,43.44a12.022,12.022,0,1,0,12.114,12.023A12.1,12.1,0,0,0,392.3,43.44Zm0,18.918a6.9,6.9,0,1,1,6.569-6.894A6.717,6.717,0,0,1,392.3,62.358Z" transform="translate(-334.545 -35.176)" fill="#242424" />
                                    <path id="Trazado_332" data-name="Trazado 332" d="M427.064,43.385a8.186,8.186,0,0,0-6.056,2.563V44H415.42v22.85h5.588V53.461c0-3.048,1.919-4.9,4.607-4.9,2.73,0,4.564,1.944,4.564,4.9V66.854h5.588V52.314A8.562,8.562,0,0,0,427.064,43.385Z" transform="translate(-342.482 -35.165)" fill="#242424" />
                                    <path id="Trazado_333" data-name="Trazado 333" d="M449.352,33.145a3.133,3.133,0,0,0-3.157,3.182,3.171,3.171,0,0,0,3.157,3.228,3.1,3.1,0,0,0,3.071-3.228A3.062,3.062,0,0,0,449.352,33.145Z" transform="translate(-349.414 -33.145)" fill="#242424" />
                                    <rect id="Rectángulo_137" data-name="Rectángulo 137" width="5.588" height="22.85" transform="translate(97.123 8.839)" fill="#242424" />
                                    <path id="Trazado_334" data-name="Trazado 334" d="M469.926,48.524a6.662,6.662,0,0,1,5.546,2.96l3.967-3a11.3,11.3,0,0,0-9.512-5.04,12,12,0,1,0,0,24,11.3,11.3,0,0,0,9.512-5.038L475.472,59.4a6.663,6.663,0,0,1-5.546,2.962,6.926,6.926,0,0,1,0-13.833Z" transform="translate(-352.031 -35.176)" fill="#242424" />
                                    <path id="Trazado_335" data-name="Trazado 335" d="M488.594,71.422V71.4a.424.424,0,0,0,.336-.389.475.475,0,0,0-.158-.369.988.988,0,0,0-.563-.122,3.18,3.18,0,0,0-.622.051v1.679h.375v-.677h.178c.207,0,.306.083.336.266a1.454,1.454,0,0,0,.128.411h.405a1.326,1.326,0,0,1-.119-.421A.491.491,0,0,0,488.594,71.422Zm-.444-.124h-.178v-.481a.925.925,0,0,1,.208-.019c.237,0,.346.1.346.256C488.525,71.227,488.357,71.3,488.15,71.3Z" transform="translate(-358.738 -40.519)" fill="#262728" />
                                    <path id="Trazado_336" data-name="Trazado 336" d="M487.917,69.488a1.689,1.689,0,1,0,1.66,1.679A1.668,1.668,0,0,0,487.917,69.488Zm.01,3.02a1.345,1.345,0,1,1,1.234-1.331A1.266,1.266,0,0,1,487.927,72.508Z" transform="translate(-358.436 -40.315)" fill="#242424" />
                                </g>
                            </svg>}
                        <em style={{ display: 'none' }}>V.1.1 {props.environment}</em></b>
                    <span>{props.project && props.project.brand ? props.project.brand.subtitle : props.logotype.subtitle} </span>

                </div>
                <div className='audio' onClick={() => {
                    if (props.audio) {
                        props.setAudio(false)
                        audioOff()
                    }
                    else {
                        props.setAudio(true)
                        audioOn()
                    }
                }}>
                    {
                        props.audio && <i className="las la-volume-up"></i>
                    }
                    {
                        !props.audio && <i className="las la-volume-mute"></i>
                    }
                </div>
                {
                    !props.user && directory === 'login' && <div className='login'>

                        <Form type={'Form-login'} method={'post'} path={'/directory/session'} inputs={
                            [
                                {
                                    name: 'username',
                                    title: "Usuario",
                                    type: 'text',
                                    placeholder: 'Escriba su usuario...',
                                },
                                {
                                    name: 'signature',
                                    title: "Contraseña",
                                    type: 'password',
                                    placeholder: 'Escriba su contraseña...',
                                    secondary: {
                                        onClick: () => {
                                            setDirectory('recover')
                                        },
                                        text: 'Recuperar clave'
                                    },
                                }
                            ]
                        }
                            secondary={
                                {
                                    "title": "Registrarse",
                                    "onClick": () => {
                                        setDirectory('register')
                                    }
                                }
                            }
                            submit={{
                                "icon": "las la-arrow-right",
                                "title": "Acceder"
                            }}
                            onSuccess={(response: any) => {
                                setDirectory(null)
                                props.setRole(response.data.role)
                                props.setUser(response.data)
                            }}
                        />
                    </div>

                }
                {
                    !props.user && directory === 'recover' && <div className='login'>
                        <div className='register-success'>
                            Indique el email con el que se registró. Se enviará una clave temporal a su email y por seguridad bloquearemos su anterior contraseña.
                        </div>
                        <Form type={'Form-login'} method={'post'} path={'/directory/recovery'} inputs={
                            [

                                {
                                    name: 'email',
                                    title: "Email",
                                    type: 'text',
                                    placeholder: 'Escriba su email...',
                                }
                            ]
                        }
                            secondary={
                                {
                                    "title": "Volver",
                                    "onClick": () => {
                                        setDirectory('register')
                                    }
                                }
                            }
                            submit={{
                                "icon": "las la-arrow-right",
                                "title": "Recuperar"
                            }}
                            onSuccess={(response: any) => {
                                setDirectory('recover-success')
                            }}
                        />
                    </div>

                }
                {
                    !props.user && directory === 'recover-success' && <div className='login'>
                        <div className='register-success'>
                            Se ha enviado una clave temporal a su email.
                        </div>
                    </div>
                }
                {
                    props.user && directory === 'account' && <div className='login'>

                        <Form type={'Form-login'} method={'post'} path={'/directory/user'}
                            submit={
                                {
                                    "icon": "las la-arrow-right",
                                    "title": "Guardar"

                                }
                            }
                            default={props.user}
                            secondary={
                                {
                                    "title": "Atrás",
                                    "onClick": () => {
                                        setDirectory(null)
                                    }
                                }
                            }
                            inputs={
                                [
                                    {
                                        name: 'name',
                                        title: "Nombre",
                                        type: 'text',
                                    },
                                    {
                                        name: 'surname',
                                        title: "Apellido",
                                        type: 'text',
                                    },
                                    {
                                        name: 'username',
                                        title: "Username",
                                        description: "Necesario para acceder",
                                        type: 'text',
                                    }
                                ]
                            } />
                    </div>

                }
                {registerSuccess && <div className='login'>
                    <div className='register-success'>
                        Se ha registrado correctamente en la plataforma, recibirá un email cuando los administradores del sistema aprueben su solicitud.
                    </div>
                </div>}
                {
                    !registerSuccess && !props.user && directory === 'register' && <div className='login'>

                        <Form type={'Form-login'} method={'post'} path={'/directory/user'}
                            autocomplete={'new-password'}
                            submit={
                                {
                                    "icon": "las la-arrow-right",
                                    "title": "Crear Cuenta"

                                }
                            }
                            onSuccess={(response: any) => {
                                setRegisterSuccess(true)
                            }}
                            default={{
                                signature: ''
                            }}
                            secondary={
                                {
                                    "title": "Acceder",
                                    "onClick": () => {
                                        setDirectory('login')
                                    }
                                }
                            }
                            inputs={
                                [
                                    {
                                        name: 'group',
                                        title: "Grupo Hospitalario",
                                        description: "Código de acceso que le han facilitado",
                                        type: 'text',
                                    }, {
                                        name: 'name',
                                        title: "Nombre",
                                        type: 'text',
                                    },
                                    {
                                        name: 'surname',
                                        title: "Apellido",
                                        type: 'text',
                                    },
                                    {
                                        name: 'username',
                                        title: "Username",
                                        description: "Necesario para acceder",
                                        type: 'text',
                                    },
                                    {
                                        name: 'email',
                                        title: "Email",
                                        type: 'text',
                                    },
                                    {
                                        name: 'signature',
                                        title: "Contraseña",
                                        type: 'password',
                                    }
                                ]
                            } />
                    </div>

                }
                {
                    props.project && <div className='project-selected'>

                        <div className='project-title' onClick={() => {
                            props.setProject(null)
                            // redirect home
                            navigate(`/projects`);

                        }}>

                            <span> <i className="las la-laptop-medical"></i>{props.project.name}</span>
                            <i className='las la-times'></i>
                        </div>
                    </div>
                }
                {
                    directory === null && props.user && menu.map((item: any, index: any) => {
                        if (!item.roles || !item.roles.includes(props.role)) {
                            return null
                        }
                        if (item.url) {
                            return <React.Fragment key={index}>

                                <a target={'_blank'} rel={'noreferrer'} key={index} href={item.url} onMouseEnter={() => { props.audio && soundEnter() }} onClick={() => { props.audio && soundClick() }} >
                                    {item.noci && <i className='image' style={{ backgroundImage: `url(/graphics/images/icons/${item.noci}.png)` }} />}
                                    {item.icon && <i className={item.icon}></i>}

                                    {item.text} </a>

                            </React.Fragment>
                        }
                        else if (item.section) {
                            return <React.Fragment key={index}>

                                <a target={'_blank'} rel={'noreferrer'} key={index} href={item.url} onMouseEnter={() => { props.audio && soundEnter() }} onClick={(e) => {
                                    e.stopPropagation()
                                    // cancel event
                                    props.audio && soundClick()
                                    setDirectory(item.section)

                                }} >
                                    {item.noci && <img alt="" src={`/graphics/images/icons/${item.noci}`} />}
                                    {item.icon && <i className={item.icon}></i>}
                                    {item.text} </a>

                            </React.Fragment>
                        }
                        else if (item.to) {
                            return <React.Fragment key={index}>

                                <CustomLink role={props.role} key={index} to={item.to} childrens={item.childrens} soundEnter={() => { props.audio && soundEnter() }} soundSection={() => { props.audio && transitionLeft() }} soundClick={() => {
                                    setDirectory(null);
                                    props.audio && soundClick()
                                }} >
                                    {item.noci && <img alt="" src={`/graphics/images/icons/${item.noci}`} />}
                                    {item.icon && <i className={item.icon}></i>}

                                    {item.text} </CustomLink>

                            </React.Fragment>
                        }
                        else {
                            return <p key={index}>{item.text}</p>
                        }


                    })}

                <div className='legal'>
                    <div className='version'><VersionComponent /></div>

                    Este software es de uso exclusivo para usuarios de <b title={'Entorno ' + props.environment} onClick={() => {
                        if (props.environment === 'dev') {
                            props.setEnvironment('pro')
                        }
                        else {
                            props.setEnvironment('dev')
                        }
                    }} >la plataforma</b>. Todo uso no autorizado será notificado al Grupo de Delitos Informáticos de la Comisión Europea.
                    <br />
                    <br />
                    Todo documento generado por este software es de uso exclusivo para el usuario en el periodo de vigencia de su suscripción con la plataforma. Este software no tiene ninguna validad legal sobre los datos facilitados en el mismo.
                </div>
            </div>
        </>
    );
};

type LinkPropsMore = LinkProps & {
    soundEnter: any,
    soundClick: any,
    soundSection: any,
    childrens: any[]
}

function CustomLink({ role, title, children, childrens, soundSection, soundEnter, soundClick, to, ...props }: LinkPropsMore) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: false });
    let className: string = 'desactive'

    if (match) className = "active"
    return (
        <><Link
            data-tip={title}
            className={className}
            to={to}
            {...props}
            onMouseEnter={() => {
                soundEnter()

            }}
            onClick={() => {
                soundClick()
                setTimeout(() => {
                    childrens && soundSection()
                }, 400);
            }}
        >
            {children}
        </Link>
            {
                className === 'active' && childrens && <div className="section">{
                    childrens.map((child: any, index: any) => {
                        if (!child.roles || !child.roles.includes(role)) {
                            return null
                        }
                        return <CustomLink title={child.title} childrens={child.childrens} key={index} to={child.to} soundSection={soundSection} soundEnter={() => { soundEnter() }} soundClick={() => { soundClick() }}
                        >
                            {child.noci && <img alt="" src={`/graphics/images/icons/${child.noci}`} />}
                            {child.icon && <i className={child.icon}></i>}
                            {child.text} </CustomLink>
                    })}
                </div>
            }</>

    );
}
const ShowTheLocationWithRouter = (Menu);

export default connect(mapStateToProps, mapDispatchToProps)(ShowTheLocationWithRouter)

