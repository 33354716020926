

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useReducer, useState } from "react";

function Groups() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    const [update, setUpdate] = useState<any>(false)

    return (

        <>
            <Header icon={'las la-layer-group'} title="Entidades" description="Grupos de Centros de Trabajo" buttons={[{
                text: "Nueva Entidad",
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">

                {
                    destruction && <Form
                        method="delete"
                        header={
                            {
                                title: 'Eliminar Entidad',
                                description: 'Esta acción no se puede deshacer',
                                icon: 'las la-trash-alt'
                            }
                        }
                        path={`/directory/group?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                    />}
                {form && <Form
                    method="post"
                    header={{
                        icon: "las la-layer-group",
                        title: "Crear Entidad",
                    }}
                    path="/directory/group"
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setForm(false)
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [

                            {
                                name: 'name',
                                title: "Entidad",
                                description: 'Nombre del grupo de centros de trabajo',
                                type: 'text',
                            },
                            {
                                name: 'signature',
                                title: "Código de Registro",
                                description: 'Texto facilitado a los usuarios para el registro en esta entidad',
                                type: 'text',
                            },
                            {
                                name: 'status',
                                type: 'select',

                                title: "Estado de Acceso",
                                description: 'Bloquea o permite a los usuarios de la entidad el login en la plataforma',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'ACTIVE',
                                        label: 'Activado'
                                    },
                                    {
                                        id: 'INACTIVE',
                                        label: 'Desactivado'
                                    }
                                ]
                            },
                        ]
                    } />}
                {update && <Form
                    path={'/directory/group?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    header={{
                        icon: "las la-layer-group",
                        title: "Editar Entidad",
                    }}
                    onSuccess={() => {
                        setUpdate(false)

                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Entidad",
                                description: 'Nombre del grupo de centros de trabajo',
                                type: 'text',
                            },
                            {
                                name: 'signature',
                                title: "Código de Registro",
                                description: 'Texto facilitado a los usuarios para el registro en esta entidad',
                                type: 'text',
                            },
                            {
                                name: 'status',
                                type: 'select',

                                title: "Estado de Acceso",
                                description: 'Bloquea o permite a los usuarios de la entidad el login en la plataforma',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'ACTIVE',
                                        label: 'Activado'
                                    },
                                    {
                                        id: 'INACTIVE',
                                        label: 'Desactivado'
                                    }
                                ]
                            },

                        ]
                    } />}
                <Widget >
                    <Table title="Listado de Entidades" reload={tableKey}

                        search={false}
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                }, {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },
                                {
                                    name: 'name',
                                    title: "Entidad",
                                    width: 400,
                                    render: (item: any) => {
                                        return `${item.name}`
                                    }
                                },

                                {
                                    name: 'signature',
                                    title: "Código de Registro",
                                    width: 200
                                },
                                {
                                    name: 'status',
                                    title: "Acceso",
                                    width: 130,
                                    render: (item: any) => {
                                        return <div className={`Status Status-${item.status}`}>{item.status}</div>
                                    }
                                }

                            ]
                        }
                        method="get"
                        path="/directory/group"
                    />
                </Widget>

            </div>
        </>

    );
}

export default Groups
