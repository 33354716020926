

import Notifications from "../../../../../componets/Notifications";
import { useState } from "react";

function List() {

    return (

        <Notifications />


    );
}

export default List
